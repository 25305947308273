import axiosAuthInstance from "../../../services/api/authentication";
import { CHECK_IMEI_GIFT, CHECK_IMEI_GIFT_ERROR } from "_models/redux/types";

// Función de acción

export const checkImeiGiftAction = (params) => async (dispatch) => {
    const { token, imei } = params || "";
    console.log("checkSerialAction -> params", params);
    try {
        const response = await axiosAuthInstance.patch(
            `api/leads/steps/gift/check-imei`,
            { imei },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: CHECK_IMEI_GIFT,
            payload: data || {},
            status: status || {},
            method: "patch",
        });
    } catch (e) {
        dispatch({
            type: CHECK_IMEI_GIFT_ERROR,
            payload: e || {},
        });
    }
};
