import React from "react";

//Styles MUI:
import {
    gridItemContainerCard,
    typographySubtitleCard,
    typographyTitleCard,
} from "./styles-mui/checkCardIMEIStylesMUI";

//Material UI:
import { Grid, Typography, CircularProgress, TextField } from "@mui/material";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function CheckCardIMEIComponent({
    handleSubmit,
    onSubmit,
    register,
    registerOptions,
    errors,
    imeiValue,
    setImeiValue,
    response,
    responseErrors,
    createLeadLoading,
    initRegisterObj,
    imeiLoading,
}) {
    return (
        <Grid item xs={12} md={4} xl={3.5} sx={gridItemContainerCard}>
            {createLeadLoading ? (
                <CircularProgress className="Circular-progress" />
            ) : initRegisterObj?.status >= 200 &&
              initRegisterObj?.status < 300 ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid item xs={12}>
                        <Typography sx={typographyTitleCard}>
                            Por IMEI
                        </Typography>
                        <Typography sx={typographySubtitleCard}>
                            Obtén el{" "}
                            <Typography
                                variant="span"
                                sx={{ fontWeight: "bold" }}
                            >
                                IMEI
                            </Typography>{" "}
                            de tu equipo marcando al{" "}
                            <Typography
                                variant="span"
                                sx={{ fontWeight: "bold" }}
                            >
                                <a
                                    style={{
                                        textDecoration: "none",
                                        color: "#fc2bdc",
                                    }}
                                    href="tel:*#06#"
                                >
                                    *#06#
                                </a>
                            </Typography>
                        </Typography>

                        <TextField
                            sx={{ my: { xs: 1, md: 2 } }}
                            fullWidth
                            {...register(
                                "imeiValue",
                                registerOptions.imeiValue
                            )}
                            id="outlined-basic"
                            label="IMEI"
                            variant="outlined"
                            value={imeiValue || ""}
                            onChange={(event) =>
                                setImeiValue(event.target.value)
                            }
                        />
                        <span>
                            {errors?.imeiValue && (
                                <p className="required-incidence">
                                    {" "}
                                    {errors.imeiValue.message}
                                </p>
                            )}
                        </span>
                        <span>
                            {errors?.imeiValue &&
                                ["minLength", "maxLength"].indexOf(
                                    errors?.imeiValue.type
                                ) !== -1 && (
                                    <p className="required-incidence">
                                        El IMEI debe ser de 15 dígitos
                                    </p>
                                )}
                        </span>
                    </Grid>

                    <Grid
                        container
                        className="required-incidence error-response-container"
                    >
                        <Grid
                            item
                            xs={12}
                            className="required-incidence error-response"
                        >
                            {response?.data?.detail}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        className="required-incidence error-response-container"
                    >
                        {typeof responseErrors !== "undefined"
                            ? responseErrors.map((reElement, reIndex) => {
                                  return typeof reElement.message !==
                                      "undefined" ? (
                                      <Grid
                                          key={reIndex}
                                          item
                                          xs={12}
                                          className="required-incidence error-response"
                                      >
                                          {reElement.message}
                                      </Grid>
                                  ) : (
                                      ""
                                  );
                              })
                            : null}
                    </Grid>

                    <Grid item xs={12}>
                        <CustomButtonComponent
                            text={"Verificar"}
                            bgColor={"violet"}
                            height={"50px"}
                            async={true}
                            loading={imeiLoading}
                        />
                    </Grid>
                </form>
            ) : (
                <p>{initRegisterObj?.response?.data?.detail}</p>
            )}
        </Grid>
    );
}

export default CheckCardIMEIComponent;
