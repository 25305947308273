export const cardContainer = {
    padding: "20px",
    maxWidth: "310px",
    maxHeight: "85px",
    boxShadow: "0px 3px 30px #ba4ae38f",
    borderRadius: "10px",
    width: "100%",
    fontFamily: '"Poppins-Regular"',
    color: "#ffffff",
    ".header-card": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",

        "&.card-title": {
            display: "flex",
            gap: "15px",
            fontSize: "15px !important",
        },

        "&.card-title img": {
            width: "40px",
            height: "24px",
        },
        "&.card-change": {
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "9px",
            letterSpacing: "0.3px",
        },
    },

    ".card-body": {
        marginTop: "5px",
        fontSize: "15px",
        ".number-card": {
            fontSize: "15px",
        },
    },
};

export const backgroundColor = (isPurple) =>
    isPurple
        ? "transparent linear-gradient(90deg, #b546f0 0%, #c74df2 50%, #8677f4 100%) 0% 0% no-repeat padding-box"
        : "transparent linear-gradient(90deg, #CC55DF 0%, #EF4AD9 51%, #E061D7 100%) 0% 0% no-repeat padding-box";
