import React from "react";
//Redux:
import { GiftLayoutPage } from "../components/shared/GiftLayoutPage";
import { Box, Grid, Typography } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Link, useLocation } from "react-router-dom";
import { iconArrowBack } from "../styles-mui/giftStyleMUI";
import CardVerification from "../components/gift-verification/card-verification/CardVerification";
const GiftVerificationPage = () => {
    const location = useLocation();
    const tokenLead = location.state?.tokenLead || "";
    return (
        <Box className="gift-verification-container" width={"100%"}>
            <GiftLayoutPage>
                <Grid
                    item
                    xs={12}
                    md={7}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Link to="/esim-gift/coverage" state={{ tokenLead }}>
                        <ArrowCircleLeftIcon sx={iconArrowBack} />
                    </Link>
                    <Typography
                        variant="p"
                        fontSize={{ xs: 40, md: 65 }}
                        className="font-medium white-color"
                        lineHeight={{ xs: 1.2, md: 1 }}
                    >
                        Código de
                    </Typography>
                    <Typography
                        variant="p"
                        fontSize={{ xs: 42, md: 78 }}
                        mb={{ xs: "30px", md: 0 }}
                        className="font-semibold purple-color"
                    >
                        Verificación
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={5}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <CardVerification />
                </Grid>
            </GiftLayoutPage>
        </Box>
    );
};

export default GiftVerificationPage;
