import { Route, Routes } from "react-router-dom";

import ForgotPasswordPage from "modules/forgot-password/page";
import ErrorForgotPasswordPage from "modules/forgot-password/component/ErrorComponent";
import SecurityCodeForgotPasswordPage from "modules/forgot-password/component/SecurityCode";
import SuccessForgotPasswordPage from "modules/forgot-password/component/SuccessComponent";

import ResetPasswordPage from "modules/reset-password/ResetPassword";
import ErrorResetPasswordPage from "modules/reset-password/components/ErrorComponent";
import ExpiredResetPasswordPage from "modules/reset-password/components/ExpiredComponnet";
import SuccessResetPasswordPage from "modules/reset-password/components/SuccessComponent";
import ResetPasswordValidation from "modules/reset-password/validation";

import SignUpContainer from "modules/sign-up/SignUpContainer";
import SecurityCodeSignUpPage from "modules/sign-up/components/SecurityCode";
import SetPasswordSignUpPage from "modules/sign-up/components/SetPassword";
import SuccessSignUpPage from "modules/sign-up/components/SuccessComponent";

import GiftContainer from "modules/gift/GiftContainer";

import ErrorPage from "modules/error/page";
import LoaderPage from "modules/loader/page";
import LoginContainer from "modules/login/LoginContainer";
import MenuContainer from "modules/menu/MenuContainer";
import ClientMenuContainer from "modules/client-menu/ClientMenuContainer";

import PurchaseContainer from "modules/purchase/container";
import AddressPurchasePage from "modules/purchase/pages/ShipAddressPurchasePage";
import CompatibilityPurchasePage from "modules/purchase/pages/CompatibilityPurchasePageComponent";
import CompatiblePurchasePage from "modules/purchase/pages/compatible";
import ComprobarCompatibilidadPurchasePage from "modules/purchase/pages/comprobar-compatibilidad";
import ConfirmationPurchasePage from "modules/purchase/pages/confirmation";
import NoCompatiblePurchasePage from "modules/purchase/pages/no-compatible";
import PaymentPurchasePage from "modules/purchase/pages/payment";
import PurchaseProductsPage from "modules/purchase/pages/products";

import ActivationPage from "modules/activation/page";
import ICCActivationPage from "modules/activation/pages/ICC-code";
import ErrorActivationPage from "modules/activation/pages/error";
import NewNumberSuccessActivationPage from "modules/activation/pages/new-number-success";
import PortabilityActivationPage from "modules/activation/pages/portability";
import PortabilitySuccessActivationPage from "modules/activation/pages/portability-success";

import PrivatePage from "router/pages/private";

import PrivateRoutes from "./private";
import PublicRoutes from "./public";

import AddressPage from "modules/compras/pages/address";
import CheckCompatibilityImeiComponent from "modules/compras/pages/CheckCompatibilityImeiComponent";
import CompatibleDevicePage from "modules/compras/pages/compatible";
import ComprobarCompatibilidadDevicePage from "modules/compras/pages/comprobar-compatibilidad";
import NoCompatibleDevicePage from "modules/compras/pages/no-compatible";
import PaymentPackages from "modules/compras/pages/payment";
import BPurchaseProductsPage from "modules/compras/pages/products";

import WbcAppPurchasePage from "modules/portal/pages/wbc-app-purchase/page";
import WbcPurchasePageComponent from "modules/portal/pages/wbc-purchase/WbcPurchasePageComponent";

import EsimActivationPage from "modules/landings/pages/esim-activation/page";
import QRNotFoundPage from "modules/landings/pages/esim-activation/not-found";

import InvoicePage from "modules/invoice-landing/invoicePage";

import NumberChangedPage from "modules/number-changed/number-changed-page";

import Gift from "modules/landings/gift/gift-page";
import GiftActivationPage from "modules/landings/pages/gift-activation/page";

import GiftConfirmationPage from "modules/landings/pages/gift-confirmation/page";
import AllRoutes from "./all";
import ComponentShowInvoices from "modules/invoice-landing/components/ComponentShowInvoices";
import LeadPage from "modules/lead-landing/LeadPage";

import ComponenteGneric from "modules/forgot-password/component/ComponentselectsPinChannel";
import CodeOptionsToVerifyPinComponent from "modules/forgot-password/component/ComponentselectsPinChannel";
import { GilftCompatibilityPage } from "modules/gift/pages/GilftCompatibilityPage";
import GiftMainFormComponent from "modules/gift/components/GiftMainFormComponent";
import { GiftCoveragePage } from "modules/gift/pages/GiftCoveragePage";
import GiftVerificationPage from "modules/gift/pages/GiftVerificationPage";
import { GiftEmailPage } from "modules/gift/pages/GiftEmailPage";
import { GiftWelcomePage } from "modules/gift/pages/GiftWelcomePage";
import { GiftNotSendPage } from "modules/gift/pages/GiftNotSendPage";
import ErrorSignUpPageGift from "modules/gift/components/ErrorComponent";

const NavigationRoutes = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PublicRoutes>
                        <MenuContainer />
                    </PublicRoutes>
                }
            />
            <Route
                path="/client"
                element={
                    <PublicRoutes>
                        <ClientMenuContainer />
                    </PublicRoutes>
                }
            />
            <Route
                path="/a"
                element={
                    <PublicRoutes>
                        <PurchaseContainer />
                    </PublicRoutes>
                }
            />
            <Route
                path="/purchase"
                element={
                    <PublicRoutes>
                        <PurchaseContainer />
                    </PublicRoutes>
                }
            />

            <Route
                path="/purchase/compatibility"
                element={
                    <PublicRoutes>
                        <CompatibilityPurchasePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/purchase/compatible"
                element={
                    <PublicRoutes>
                        <CompatiblePurchasePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/purchase/no-compatible"
                element={
                    <PublicRoutes>
                        <NoCompatiblePurchasePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/purchase/comprobar-compatibilidad"
                element={
                    <PublicRoutes>
                        <ComprobarCompatibilidadPurchasePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/purchase/products"
                element={
                    <PublicRoutes>
                        <PurchaseProductsPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/purchase/user-address"
                element={
                    <PublicRoutes>
                        <AddressPurchasePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/purchase/payment-method"
                element={
                    <PublicRoutes>
                        <PaymentPurchasePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/leads/payments"
                element={
                    <PublicRoutes>
                        <PaymentPurchasePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/purchase/purchase-confirmation"
                element={
                    <PublicRoutes>
                        <ConfirmationPurchasePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/activation"
                element={
                    <PublicRoutes>
                        <ActivationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/activation/ICC-code"
                element={
                    <PublicRoutes>
                        <ICCActivationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/activation/portability"
                element={
                    <PublicRoutes>
                        <PortabilityActivationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/activation/new-number-success"
                element={
                    <PublicRoutes>
                        <NewNumberSuccessActivationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/activation/portability-success"
                element={
                    <PublicRoutes>
                        <PortabilitySuccessActivationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/activation/error"
                element={
                    <PublicRoutes>
                        <ErrorActivationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/login"
                element={
                    <PublicRoutes>
                        <LoginContainer />
                    </PublicRoutes>
                }
            />

            <Route
                path="/forgot-password"
                element={
                    <PublicRoutes>
                        <ForgotPasswordPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/forgot-password/security-code"
                element={
                    <PublicRoutes>
                        <SecurityCodeForgotPasswordPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/forgot-password/media-selection-pin"
                element={
                    <PublicRoutes>
                        <ComponenteGneric />
                    </PublicRoutes>
                }
            />

            <Route
                path="/forgot-password-success"
                element={
                    <PublicRoutes>
                        <SuccessForgotPasswordPage />
                    </PublicRoutes>
                }
            />
            <Route
                path="/forgot-password-error"
                element={
                    <PublicRoutes>
                        <ErrorForgotPasswordPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/reset-password/set-password"
                element={
                    <PublicRoutes>
                        <ResetPasswordPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/reset-password/:token/:key"
                element={
                    <PublicRoutes>
                        <ResetPasswordValidation />
                    </PublicRoutes>
                }
            />

            <Route
                path="/test"
                element={
                    <PublicRoutes>
                        <CodeOptionsToVerifyPinComponent />
                    </PublicRoutes>
                }
            />

            <Route
                path="/reset-password-expired-page"
                element={
                    <PublicRoutes>
                        <ExpiredResetPasswordPage from={"password-expired"} />
                    </PublicRoutes>
                }
            />

            <Route
                path="/reset-password-success-page"
                element={
                    <PublicRoutes>
                        <SuccessResetPasswordPage />
                    </PublicRoutes>
                }
            />
            <Route
                path="/reset-password-error-page"
                element={
                    <PublicRoutes>
                        <ErrorResetPasswordPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/sign-up"
                element={
                    <PublicRoutes>
                        <SignUpContainer />
                    </PublicRoutes>
                }
            />
            <Route
                path="/sign-up/security-code"
                element={
                    <PublicRoutes>
                        <SecurityCodeSignUpPage />
                    </PublicRoutes>
                }
            />
            <Route
                path="/sign-up/set-password"
                element={
                    <PublicRoutes>
                        <SetPasswordSignUpPage />
                    </PublicRoutes>
                }
            />
            <Route
                path="/sign-up-success"
                element={
                    <PublicRoutes>
                        <SuccessSignUpPage />
                    </PublicRoutes>
                }
            />
            <Route
                path="/esim-gift-error"
                element={
                    <PublicRoutes>
                        <ErrorSignUpPageGift />
                    </PublicRoutes>
                }
            />

            <Route
                path="/notfound"
                element={
                    <PublicRoutes>
                        <ErrorPage />
                    </PublicRoutes>
                }
            />
            <Route
                path="/error"
                element={
                    <PublicRoutes>
                        <ErrorPage />
                    </PublicRoutes>
                }
            />
            <Route
                path="/esim-gift"
                element={
                    <PublicRoutes>
                        <GiftContainer />
                    </PublicRoutes>
                }
            >
                <Route
                    path=""
                    element={
                        <PublicRoutes>
                            <GiftMainFormComponent />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="compatibility"
                    element={
                        <PublicRoutes>
                            <GilftCompatibilityPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="coverage"
                    element={
                        <PublicRoutes>
                            <GiftCoveragePage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="verification"
                    element={
                        <PublicRoutes>
                            <GiftVerificationPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="email"
                    element={
                        <PublicRoutes>
                            <GiftEmailPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="welcome"
                    element={
                        <PublicRoutes>
                            <GiftWelcomePage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="not-send"
                    element={
                        <PublicRoutes>
                            <GiftNotSendPage />
                        </PublicRoutes>
                    }
                />
            </Route>

            <Route
                path="/loader"
                element={
                    <PublicRoutes>
                        <LoaderPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/compras/no-compatible"
                element={
                    <PublicRoutes>
                        <NoCompatibleDevicePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/compras/comprobar-compatibilidad"
                element={
                    <PublicRoutes>
                        <ComprobarCompatibilidadDevicePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/compras/products"
                element={
                    <PublicRoutes>
                        <BPurchaseProductsPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/compras/payment-method"
                element={
                    <PublicRoutes>
                        <PaymentPackages />
                    </PublicRoutes>
                }
            />

            <Route
                path="/compras/user-address"
                element={
                    <PublicRoutes>
                        <AddressPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/app/cerrar"
                element={
                    <PublicRoutes>
                        <WbcAppPurchasePage flow="" />
                    </PublicRoutes>
                }
            />

            <Route
                path="/completado/compra/:uuid"
                element={
                    <PublicRoutes>
                        <WbcPurchasePageComponent flow="" />
                    </PublicRoutes>
                }
            />

            <Route
                path="/gift/activation"
                element={
                    <PublicRoutes>
                        <GiftActivationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/gift/confirmation"
                element={
                    <PublicRoutes>
                        <GiftConfirmationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/qr/activar"
                element={
                    <PublicRoutes>
                        <EsimActivationPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/pagina-no-encontrada"
                element={
                    <PublicRoutes>
                        <QRNotFoundPage />
                    </PublicRoutes>
                }
            />

            <Route path="/factura" element={<InvoicePage />} />

            <Route path="/factura/:uuid" element={<InvoicePage />} />

            <Route
                path="/factura/:uuid/descarga"
                element={<ComponentShowInvoices />}
            />

            <Route
                path="/orden-no-encontrada"
                element={
                    <PublicRoutes>
                        <QRNotFoundPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/numero-actualizado"
                element={
                    <PublicRoutes>
                        <NumberChangedPage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/gift"
                element={
                    <PublicRoutes>
                        <Gift />
                    </PublicRoutes>
                }
            />

            <Route
                path="/b"
                element={
                    <PublicRoutes>
                        <CheckCompatibilityImeiComponent />
                    </PublicRoutes>
                }
            />
            <Route
                path="/compras"
                element={
                    <PublicRoutes>
                        <CheckCompatibilityImeiComponent />
                    </PublicRoutes>
                }
            />

            <Route
                path="/compras/compatible"
                element={
                    <PublicRoutes>
                        <CompatibleDevicePage />
                    </PublicRoutes>
                }
            />

            <Route
                path="/micompra"
                element={
                    <AllRoutes>
                        <LeadPage />
                    </AllRoutes>
                }
            />

            <Route
                path="/*"
                element={
                    <PrivateRoutes>
                        <PrivatePage />
                    </PrivateRoutes>
                }
            />
        </Routes>
    );
};

export default NavigationRoutes;
