import { useContext, useEffect } from "react";

//React Router Dom:
import { useNavigate } from "react-router-dom";

//Redux:
import { connect } from "react-redux";
import { cleanCompleteProcessAction } from "../../../_models/redux/complete-process/clean";

//Context:
import { AppContext } from "_models/context/app";

//Pixels:
import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import FacebookPixel from "react-facebook-pixel";

//Material UI:
import { Container, Grid, Typography } from "@mui/material";

//Icons MUI:
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

//Styles MUI:
import {
    containerClientMenuForm,
    gridContainerClientMenu,
    iconArrowBack,
    titleContent,
    titleClientMenuOne,
    titleClientMenuTwo,
    titleClientMenuThree,
    typographyDescription,
    typographyDescriptionTitle,
} from "../styles-mui/clientMenuStylesMUI";

//Components:
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import ClientMenuButtonComponent from "./ClientMenuButtonsComponent";

const ClientMenuComponent = (props) => {
    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_PURCHASE_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** ***/

    /*** TIKTOK pixels  ***/
    const tiktokPixelId = process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** ***/

    /*** FACEBOOK pixels  ***/
    const facebookPixelId =
        process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        autoConfig: true,
        debug: debugPixels,
    };

    /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_PURCHASE_CLARITY_PIXEL;

    /*** ***/

    useEffect(() => {
        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );
        FacebookPixel.pageView();
        /*** ***/

        // /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        // /*** ***/
    }, []);

    const appContext = useContext(AppContext);
    const { setPressed } = appContext;

    let navigate = useNavigate();

    useEffect(() => {
        return () => {
            props.cleanCompleteProcessAction();
            window.onpopstate = () => {
                setPressed(false);
            };
        };
    }, []);

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg">
                <Grid container sx={gridContainerClientMenu}>
                    <Grid item xs={12} md={6} sx={titleContent}>
                        <a href="/">
                            <ArrowCircleLeftIcon sx={iconArrowBack} />
                        </a>
                        <Typography sx={titleClientMenuOne}>Ya soy</Typography>
                        <Typography sx={titleClientMenuTwo}>Cliente</Typography>
                        <Typography sx={titleClientMenuThree}>
                            Dalefon
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={containerClientMenuForm}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={typographyDescriptionTitle}>
                                    ¡Hola, Dalelover!
                                </Typography>
                                <Typography sx={typographyDescription}>
                                    Recarga, adquiere un NoPlan, conoce el
                                    detalle de tus consumos, consulta
                                    promociones... ¡y más!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ClientMenuButtonComponent />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    completeProcess: state.completeProcess,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanCompleteProcessAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientMenuComponent);
