import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import imgEmptyCard from "assets/images/portal/pages/payment-methods/my-first-card.png";
import imgShadow from "assets/images/portal/pages/payment-methods/shadow_card.png";
import { boxContainer, buttonStyle, cardContainer } from "./emptyCardStylesMui";
import { Link } from "react-router-dom";
export const EmptyCard = () => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box sx={boxContainer}>
                <Box sx={cardContainer}>
                    <Box display={"flex"} alignItems={"center"} gap={"17px"}>
                        <img
                            src={imgEmptyCard}
                            alt="Tarjeta de credito"
                            width={35}
                        />
                        <Typography
                            variant="span"
                            color={"white"}
                            fontSize={19}
                        >
                            Crédito o débito
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={"17px"}
                        justifyContent={"space-between"}
                    >
                        <Typography
                            variant="span"
                            color={"white"}
                            alignSelf={"end"}
                        >
                            Mi primer tarjeta
                        </Typography>
                        <Link to={"/paquetes"}>
                            <IconButton sx={buttonStyle}>
                                <AddIcon />
                            </IconButton>
                        </Link>
                    </Box>
                </Box>
            </Box>

            <img src={imgShadow} alt="Sobre de la tarjeta de crédito" />
        </Box>
    );
};
