import React from "react";
import { EmptyCard } from "../shared/empty-card/EmptyCard";
import { Box, Checkbox, Typography } from "@mui/material";

export const EmptyContainer = () => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <EmptyCard />
            <Box width={234} textAlign={"center"}>
                <Typography variant="p" fontSize={15}>
                    Activa{" "}
                    <Checkbox
                        sx={{
                            padding: 0,
                            transform: "scale(0.8)",
                        }}
                        checked
                    />{" "}
                    <b>Guardar esta tarjeta</b> antes de realizar tu pago
                </Typography>
            </Box>
        </Box>
    );
};
