import React from "react";
import { Container, Grid, Button } from "@mui/material";
import inactiveImage from "assets/images/home/no-plan.svg";

const HomeInactive = () => {
    const testData = [
        { bgcolor: "#FD45D9", completed: 60 },
        { bgcolor: "#663CE6", completed: 30 },
        { bgcolor: "#6DF1FE", completed: 53 },
    ];

    return (
        <Container>
            <Grid container className="inactive-plan">
                <Grid item xs={12} md={6} className={"inactive-text-container"}>
                    <h2>No cuentas con ningún paquete activo</h2>
                    <p>
                        Elige el paquete de tu preferencia para seguir navegando
                        y aprovecha los beneficios que <span>Dalefon</span> te
                        ofrece.
                    </p>
                    <Button className="button" href="/paquetes">
                        Comprar un paquete
                    </Button>
                </Grid>
                <Grid item xs={6} className="inactive-image-container">
                    <img
                        title="Inactive Image"
                        alt="Inactive Image"
                        src={inactiveImage}
                        className={"inactive-image"}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default HomeInactive;
