import React from "react";

//Redux:
import { connect } from "react-redux";
import { cleanCompleteProcessAction } from "../../../_models/redux/complete-process/clean";

//Material UI:
import { Container, Grid, Box, Typography } from "@mui/material";

//CSS:
import "../../../assets/styles/css/menu/menu.css";

//Images:
import logoImage from "assets/images/logo.png";

//Hooks:
import { HomeMenuController } from "../hooks/useHomeMenuHook";

//Styles MUI:
import {
    logoContainer,
    gridContainer,
    titleContent,
    typographyTitleOne,
    typographyTitleTwo,
    typographyTitleThree,
    typographyDescriptionTitle,
    typographyDescription,
    gridItemCard,
} from "../styles/componentStyle";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const MenuComponent = ({ completeProcess, cleanCompleteProcessAction }) => {
    const { purchaseEvent, clientEvent } = HomeMenuController({
        completeProcess,
        cleanCompleteProcessAction,
    });

    return (
        <Container maxWidth="lg" className="menu-container">
            <Grid container sx={gridContainer}>
                <Grid item xs={12} md={6} sx={titleContent}>
                    <Box sx={logoContainer}>
                        <img src={logoImage} className="logo-image" />
                    </Box>
                    <Box>
                        <Typography sx={typographyTitleOne}>Somos</Typography>
                        <Typography sx={typographyTitleTwo}>
                            La telefonía
                        </Typography>
                        <Typography sx={typographyTitleThree}>
                            Morada
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={gridItemCard}>
                    <Box>
                        <Typography sx={typographyDescriptionTitle}>
                            ¡Da un salto hacia el futuro!
                        </Typography>
                        <Typography sx={typographyDescription}>
                            Este es tu portal Dalefon, gestiona tu línea de la
                            manera más sencilla.
                        </Typography>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <CustomButtonComponent
                                    text="Comprar SIM"
                                    bgColor="primary-purple"
                                    height="60px"
                                    onClick={purchaseEvent}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomButtonComponent
                                    text="Ya soy cliente"
                                    bgColor="secondary-purple"
                                    height="60px"
                                    variant="outlined"
                                    onClick={clientEvent}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    completeProcess: state.completeProcess,
});

const mapDispatchToProps = {
    cleanCompleteProcessAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
