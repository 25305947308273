import React from "react";
import { connect } from "react-redux";
import { CardGift } from "../../shared/CardGift";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";

import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import { inputCardStyle } from "../../gift-compatibility/card-zip-code/cardZipCodeStyleMUI";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { modalStyle, modalTitleStyle } from "./CarEmailStyleMUI";
import { sendEmailAndCompleteGiftAction } from "_models/redux/email-process-complete/action";
import { useCardEmail } from "modules/gift/hooks/useCardEmail";
import { cleanSendEmailAndCompleteGift } from "_models/redux/email-process-complete/clean-action";

const CardEmail = ({
    sendEmailAndCompleteGiftAction,
    sendEmailAndCompleteGift,
    cleanSendEmailAndCompleteGift,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        loading,
        open,
        errorMessage,
        onSubmit,
        handleClose,
        modalTitle,
    } = useCardEmail({
        sendEmailAndCompleteGiftAction,
        sendEmailAndCompleteGift,
        cleanSendEmailAndCompleteGift,
    });
    console.log("CardEmail -> errorMessage", errorMessage);
    return (
        <CardGift>
            <Typography
                variant="p"
                md={1}
                mb={{ xs: "39px", md: "28px" }}
                fontSize={{ xs: 18, md: 16 }}
                lineHeight={{ xs: 1.6, md: 2 }}
            >
                Recibe la información de tu eSIM, así como los pasos a seguir
                para activarla.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="p" mb={1} fontSize={13}>
                    Correo electrónico
                </Typography>
                <TextField
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    placeholder="Introduce tu correo electrónico"
                    type="email"
                    sx={inputCardStyle}
                    {...register("email", {
                        required: "El correo es obligatorio",
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Introduce un correo electrónico válido",
                        },
                    })}
                />
                {errors.email && (
                    <Typography
                        variant="span"
                        fontSize={11}
                        display={"flex"}
                        alignItems={"center"}
                        mt={"13px"}
                        sx={{ color: "red" }}
                    >
                        <ErrorOutlineIcon fontSize="11" />
                        &nbsp;{errors.email.message}
                    </Typography>
                )}

                <Box mt={"32px"} sx={{ position: "relative" }}>
                    <CustomButtonComponent
                        async={true}
                        bgColor={"primary-purple"}
                        text={"Obtener regalo"}
                        height={55}
                        loading={loading}
                    />
                </Box>
            </form>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography
                        id="modal-modal-title"
                        variant="p"
                        sx={modalTitleStyle}
                    >
                        <ErrorOutlineIcon color="error" fontSize="11" /> &nbsp;
                        {modalTitle || ""}
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{
                            mt: 2,
                            textAlign: "center",
                            fontSize: { xs: 14, md: 15 },
                        }}
                    >
                        {errorMessage || ""}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: "30px",
                        }}
                    >
                        <Button
                            variant="text"
                            sx={{ textTransform: "none", color: "#262626" }}
                            onClick={handleClose}
                        >
                            Aceptar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </CardGift>
    );
};

const mapStateToProps = (state) => ({
    sendEmailAndCompleteGift: state.sendEmailAndCompleteGift,
});

const mapDispatchToProps = {
    sendEmailAndCompleteGiftAction,
    cleanSendEmailAndCompleteGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardEmail);
