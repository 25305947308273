export const containerPending = {
    backgroundColor: "white",
    py: { xs: 4, md: 8 },
};

export const gridContainerPending = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: { xs: 0, md: "40px 0 50px" },
    margin: "0 auto",
    textAlign: "center",
};

export const gridItemContainerTexts = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const typographyTitle = {
    fontSize: { xs: 25, md: 38 },
    color: "#51328B",
    fontFamily: "Poppins-Bold",
    mt: { xs: 2, md: 4 },
    mb: { xs: 3, md: 4 },
};

export const typographyMessage = {
    mb: { xs: 3, md: 4 },
    mx: { xs: 2, md: 0 },
    fontFamily: "Poppins-Regular",
};

export const boxContainerDesc = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const boxDetail = {
    width: { xs: "80%", md: "100%" },
    background: "#FFEBEB 0% 0% no-repeat padding-box",
    border: "1px solid #DB4C4C",
    borderRadius: "10px",
    mb: { xs: 3, md: 5 },
    p: 2,
};

export const typographyDetail = {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
};
