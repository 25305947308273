import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { usePixels } from "./usePixels";

export const useCardGoodCoverage = ({
    setUserGiftAction,
    setUserGift,
    cleanSetUserGift,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pixelContact } = usePixels();
    const tokenLead = location.state?.tokenLead || "";
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        const payload = {
            name: data?.fullName,
            phone_number: data?.phoneNumber,
            notification_type: data?.notificationType,
        };

        setLoading(true);
        setUserGiftAction({
            payload,
            token: tokenLead,
        });
        pixelContact({ fullName: "", phone: "", leadToken: tokenLead });
    };

    useEffect(() => {
        if (setUserGift?.status === 200) {
            navigate("/esim-gift/verification", {
                state: {
                    tokenLead: tokenLead,
                },
            });
            cleanSetUserGift();
        } else if (setUserGift?.payload?.response?.status >= 300) {
            if (
                setUserGift?.payload?.response?.data?.context ===
                    "lead_closed_error" &&
                setUserGift?.payload?.response?.status === 409
            ) {
                navigate("esim-gift-error", {
                    state: {
                        title: setUserGift?.payload?.response?.data?.title,
                        responseMessage:
                            setUserGift?.payload?.response?.data?.detail,
                    },
                });
            }
            setOpenModal(true);
            setModalMessage(setUserGift?.payload?.response?.data?.detail);
        }
        setLoading(false);
    }, [setUserGift, navigate, tokenLead]);

    const handleDialogClose = () => {
        setOpenModal(false);
        if (setUserGift?.payload?.response?.status === 401) {
            navigate("/esim-gift");
            cleanSetUserGift();
        }
    };

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        openModal,
        modalMessage,
        handleDialogClose,
    };
};
