import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import { signUpContext } from "./context";

import { useSignUp } from "./hook";

import { loginAction } from "_models/redux/login/action";

import { setLoginDataHelper } from "_controllers/helpers/setLoginData";

//Components:
import SignUpMainPageComponent from "./SignUpMainPageComponent";

const SignUpContainer = (props) => {
    const history = useNavigate();

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [loginResponse, setLoginResponse] = useState();
    const [loginResponseCls, setLoginResponseCls] = useState({
        container: "",
        text: "",
    });

    const [variant, setVariant] = useState("determinate");
    const [progress, setProgress] = useState(0);

    const { loginAction, login } = props;

    const { sendMethod } = useSignUp({
        login,
        loginAction,
    });

    const sendLogin = (data) => {
        setVariant("indeterminate");
        sendMethod(data);
    };

    const loginValues = {
        sendLogin,

        loginResponse,
        setLoginResponse,
        loginResponseCls,
        setLoginResponseCls,

        variant,
        setVariant,
        progress,
        setProgress,

        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,
    };

    useEffect(() => {
        if (typeof login.login == "undefined") {
            return false;
        }
        const { token, expires_at, refresh_token, response } =
            login.login || {};
        if (
            typeof token != "undefined" &&
            typeof expires_at != "undefined" &&
            typeof refresh_token != "undefined"
        ) {
            setLoginResponse("¡Bienvenido!");
            setLoginResponseCls({
                container: "success-container",
                text: "success-text",
            });
            setVariant("determinate");
            setLoginDataHelper(login.login, "LoginContainer");
            history("/dashboard");
        } else if (typeof response != "undefined") {
            const { data } = response || {};
            const { detail } = data || {};
            setLoginResponse(detail || "");
            setLoginResponseCls({
                container: "error-container",
                text: "error-text",
            });
            setVariant("determinate");
            setLoginDataHelper(
                { token: null, expires_at: null, refresh_token: null },
                "LoginContainer"
            );
            history("/login");
        }
    }, [login]);

    return (
        <signUpContext.Provider value={loginValues}>
            <SignUpMainPageComponent />
        </signUpContext.Provider>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    login: state.login,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    loginAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
