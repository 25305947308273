import { useState } from "react";
import {
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IMAGES_CARDS } from "./const/images-types-cards";
import { backgroundColor, cardContainer } from "./cardPaymentStyleMUI";
import { convertToMaskCard } from "utils/helpers/mask-card/mask-card";
import { modalStyle } from "../../styles/generalStyleMui";

export const CardPayment = ({
    id,
    typeCard,
    cardNumber,
    isPurple,
    handleDeleteCard,
}) => {
    const [showModal, setShowModal] = useState(false);

    const cardImage = IMAGES_CARDS[typeCard]
        ? IMAGES_CARDS[typeCard]
        : IMAGES_CARDS.DEFAULT;
    const { prefix, numberCard } = convertToMaskCard(cardNumber);

    const handleDelete = () => {
        handleDeleteCard(id);
        setShowModal(false);
    };

    return (
        <>
            <Box
                sx={{ ...cardContainer, background: backgroundColor(isPurple) }}
            >
                <Box className="header-card">
                    <Box className="header-card card-title">
                        <img src={cardImage.src} alt={cardImage.alt} />

                        <Typography variant="p">{cardImage.title}</Typography>
                    </Box>

                    <Typography
                        variant="span"
                        className="header-card card-change"
                        onClick={() => setShowModal(true)}
                    >
                        ELIMINAR
                    </Typography>
                </Box>
                <Box className="card-body">
                    <Box className="number-card">
                        <Typography
                            variant="p"
                            display={"flex"}
                            alignItems={"center"}
                            gap={prefix.length === 14 ? 1 : 0}
                        >
                            <Typography variant="span" fontSize={25}>
                                {prefix}
                            </Typography>
                            {numberCard}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="Alerta para eliminar tarjeta"
                sx={modalStyle}
            >
                <DialogTitle className="modal-title">
                    <ErrorOutlineIcon
                        fontSize="16px"
                        sx={{ fill: "#E62121" }}
                    />
                    Eliminar tarjeta
                </DialogTitle>
                <DialogContent sx={{ width: "310px" }}>
                    <DialogContentText
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Typography
                            fontSize={14}
                            variant="div"
                            width={178}
                            textAlign={"center"}
                        >
                            La tarjeta se eliminará de tus tarjetas guardadas
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="modal-actions">
                    <Button onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button
                        autoFocus
                        sx={{
                            backgroundColor: "#EBEBEB",
                        }}
                        onClick={handleDelete}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
