import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Grid, Container, TextField, Button } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import MenuItem from "@mui/material/MenuItem";

import PortalNavigation from "modules/portal/components/navigation";
import LegalsHeader from "./LegalsHeader";
import PortalFooter from "modules/portal/components/footer";

import { getMyLegalsAction } from "_models/redux/my-legals/action";
import { cleanMyLegalsAction } from "_models/redux/my-legals/clean-action";

import { cleanMyLegalAction } from "_models/redux/my-legal/clean-action";
import { postMyLegalAction } from "_models/redux/my-legal/post-action";
import { putMyLegalAction } from "_models/redux/my-legal/put-action";
import { deleteMyLegalAction } from "_models/redux/my-legal/delete-action";

import CheckImage from "assets/images/my-cards/success.svg";
import ErrorImage from "assets/images/my-cards/error.svg";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const MyLegalsContent = (props) => {
    const {
        myLegal,
        myLegals,

        getMyLegalsAction,
        cleanMyLegalsAction,

        cleanMyLegalAction,
        postMyLegalAction,
        putMyLegalAction,
        deleteMyLegalAction,
    } = props || {};

    const { payload } = myLegals || {};

    const { legal_ids } = payload || {};

    const [loading, setLoading] = useState(false);

    const [selectedItem, setSelectedItem] = useState();
    const [selectedDefault, setSelectedDefault] = useState("");

    const [open, setOpen] = useState(false);
    const [response, setResponse] = useState();

    const [successModalResponse, setSuccessModalResponse] = useState("");
    const [openSuccessModal, setOpenSuccessModal] = useState();

    const [openCustomModal, setOpenCustomModal] = useState(false);
    const [customModalResponse, setCustomModalResponse] = useState();
    const [customModalIcon, setCustomModalIcon] = useState();

    const [legalSelectedId, setLegalSelectedId] = useState();

    const [formAction, setFormAction] = useState("add");

    const [cfdiDefaultValue, setCfdiDefaultValue] = useState(0);
    const [taxRegimeDefaultValue, setTaxRegimeDefaultValue] = useState(0);

    const registerOptions = {
        rfcInput: {
            required: "El RFC es requerido",
        },
        corporateNameInput: {
            required: "La razón social es requerida",
        },
        taxRegimeInput: {
            required: "El régimen fiscal es requerido",
            min: { value: 1, message: "El régimen fiscal es requerido" },
        },
        zipCodeInput: {
            required: "El código postal es requerido",
        },
        cfdiInput: {
            required: "El CFDI es requerido",
            min: { value: 1, message: "El CFDI es requerido" },
        },
        emailInput: {
            required: "El correo electrónico es requerido",
        },
    };

    const {
        reset,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getMyLegalsAction();

        return () => {
            cleanMyLegalAction();
            cleanMyLegalsAction();
        };
    }, []);

    const onSubmit = async (e) => {
        setLoading(true);

        if (formAction === "add") {
            postMyLegalAction({
                legal_id: e?.rfcInput,
                legal_name: e?.corporateNameInput,
                tax_regime: e?.taxRegimeInput,
                zipcode: e?.zipCodeInput,
                cfdi_use_id: e?.cfdiInput,
                email: e?.emailInput,
            });
        } else {
            putMyLegalAction(
                {
                    legal_name: e?.corporateNameInput,
                    tax_regime: e?.taxRegimeInput,
                    zipcode: e?.zipCodeInput,
                    cfdi_use_id: e?.cfdiInput,
                    email: e?.emailInput,
                },
                e?.rfcInput
            );
        }

        // {
        //     legal_id: "MOUS910809198",
        //     legal_name: "Sandra Melina Monreal Uribe",
        //     tax_regime: "603",
        //     zipcode: "62586",
        //     cfdi_use_id: "G03",
        //     email: "monrealdisandra@gmail.com",
        // }
    };

    const inputChange = (e) => {
        setValue(e?.target?.name, e?.target?.value);

        if (e?.target?.name == "taxRegimeInput") {
            setTaxRegimeDefaultValue(e?.target?.value);
        }

        if (e?.target?.name == "cfdiInput") {
            setCfdiDefaultValue(e?.target?.value);
        }
    };

    const cfdi = [
        {
            id: "G01",
            name: "Adquisición de mercancías",
        },
        {
            id: "G03",
            name: "Gastos en general",
        },
    ];

    const taxRegime = [
        {
            id: "601",
            name: "General de Ley Personas Morales",
        },
        {
            id: "603",
            name: "Personas Morales con Fines no Lucrativos",
        },
        {
            id: "606",
            name: "Arrendamiento",
        },
        {
            id: "612",
            name: "Personas Físicas con Actividades Empresariales y Profesionales",
        },
        {
            id: "620",
            name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
        },
        {
            id: "621",
            name: "Incorporación Fiscal",
        },
        {
            id: "622",
            name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
        },
        {
            id: "623",
            name: "Opcional para Grupos de Sociedades",
        },
        {
            id: "624",
            name: "Coordinados",
        },
        {
            id: "625",
            name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
        },
        {
            id: "626",
            name: "Régimen Simplificado de Confianza",
        },
    ];

    const AddForm = () => {
        return (
            <Grid container className={"my-legals-form-container"}>
                <Grid item xs={12}>
                    {formAction === "add" ? (
                        <h1>Agrega los datos de facturación</h1>
                    ) : (
                        <div></div>
                    )}
                </Grid>
                <form
                    className={"my-legals-form"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container className={"data-container"}>
                        <Grid item xs={12} className={"data-item"}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="RFC *"
                                    label="RFC *"
                                    id="rfcInput"
                                    name="rfcInput"
                                    {...register(
                                        "rfcInput",
                                        registerOptions?.rfcInput
                                    )}
                                    onChange={(e) => inputChange(e)}
                                    className="m-tb-10 form-control data-input"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <span>
                                    {errors?.rfcInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.rfcInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={"data-item"}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Razón social *"
                                label="Razón social *"
                                id="corporateNameInput"
                                name="corporateNameInput"
                                {...register(
                                    "corporateNameInput",
                                    registerOptions?.corporateNameInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-10 form-control data-input"
                            />

                            <Grid item xs={12}>
                                <span>
                                    {errors?.corporateNameInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.corporateNameInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={"data-container"}>
                        <Grid xs={12} className={"data-item"}>
                            <TextField
                                select
                                fullWidth
                                // type="number"
                                variant="outlined"
                                placeholder="Seleccione una opción"
                                label="Régimen fiscal *"
                                id="taxRegimeInput"
                                name="taxRegimeInput"
                                {...register(
                                    "taxRegimeInput",
                                    registerOptions?.taxRegimeInput
                                )}
                                defaultValue={taxRegimeDefaultValue}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-10 form-control data-input"
                            >
                                <MenuItem key={0} value={0}>
                                    {"Seleccione una opción"}
                                </MenuItem>
                                {taxRegime?.map((option) => (
                                    <MenuItem
                                        key={option?.id}
                                        value={option?.id || 0}
                                    >
                                        {option?.id + " - " + option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <span>
                                {errors?.taxRegimeInput && (
                                    <p className="required-incidence m-0">
                                        {" "}
                                        {errors?.taxRegimeInput.message}
                                    </p>
                                )}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid container className={"data-container"}>
                        <Grid item xs={12} className={"data-item"}>
                            <TextField
                                fullWidth
                                type="number"
                                variant="outlined"
                                placeholder="Código postal*"
                                label="Código postal*"
                                id="zipCodeInput"
                                name="zipCodeInput"
                                {...register(
                                    "zipCodeInput",
                                    registerOptions?.zipCodeInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-10 form-control data-input"
                            />

                            <Grid item xs={12}>
                                <span>
                                    {errors?.zipCodeInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.zipCodeInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={"data-item"}>
                            <TextField
                                select
                                fullWidth
                                type="number"
                                variant="outlined"
                                placeholder="Uso de CFDI"
                                label="Uso de CFDI"
                                id="cfdiInput"
                                name="cfdiInput"
                                {...register(
                                    "cfdiInput",
                                    registerOptions?.cfdiInput
                                )}
                                defaultValue={cfdiDefaultValue}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-10 form-control data-input"
                            >
                                <MenuItem key={0} value={0}>
                                    {"Seleccione una opción"}
                                </MenuItem>
                                {cfdi?.map((option) => (
                                    <MenuItem
                                        key={option?.id}
                                        value={option?.id || 0}
                                    >
                                        {option?.id + " - " + option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Grid item xs={12}>
                                <span>
                                    {errors?.cfdiInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.cfdiInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={"data-item"}>
                            <TextField
                                fullWidth
                                // type="number"
                                variant="outlined"
                                placeholder="Correo electrónico"
                                label="Correo electrónico"
                                id="emailInput"
                                name="emailInput"
                                {...register(
                                    "emailInput",
                                    registerOptions?.emailInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-10 form-control data-input"
                            />

                            <Grid item xs={12}>
                                <span>
                                    {errors?.emailInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.emailInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>

                    {formAction === "add" ? (
                        <Grid container className={"data-container"}>
                            <Grid
                                xs={12}
                                className={"data-item   my-legal-add-button"}
                            >
                                <CustomButtonComponent
                                    bgColor={"primary-purple"}
                                    text={"Generar RFC"}
                                    height={"50px"}
                                    loading={loading}
                                    loadingPosition="end"
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container className={"data-container"}>
                            <Grid xs={6} className={"my-legal-delete-button"}>
                                <Button onClick={setOpen} sx={{ px: 0 }}>
                                    <DeleteIcon /> Eliminar
                                </Button>
                            </Grid>
                            <Grid
                                xs={6}
                                display={"flex"}
                                justifyContent={"end"}
                            >
                                <Button type={"submit"} sx={{ px: 0 }}>
                                    Guardar cambios
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </form>
            </Grid>
        );
    };

    const setItemValues = (item = undefined) => {
        if (typeof item != "undefined") {
            setValue("rfcInput", item?.legal_id);
            setValue("corporateNameInput", item?.legal_name);
            setValue("taxRegimeInput", item?.tax_regime);
            setValue("zipCodeInput", item?.zipcode);
            setValue("cfdiInput", item?.cfdi_purpose);
            setValue("emailInput", item?.email);

            setCfdiDefaultValue(item?.cfdi_purpose);
            setTaxRegimeDefaultValue(item?.tax_regime);
        }
    };

    const selectItem = (index, item) => {
        setSelectedItem(index);
        setSelectedDefault("");

        const { legal_id } = item;

        setLegalSelectedId(legal_id);
        setFormAction("edit");

        setItemValues(item);
    };

    const selectDefaultItem = () => {
        if (selectedDefault === "") {
            setSelectedDefault("selected");
            setSelectedItem(undefined);
            setFormAction("add");

            reset();

            setCfdiDefaultValue(0);
            setTaxRegimeDefaultValue(0);
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        // border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccessModal = () => {
        setOpenSuccessModal(false);
        getMyLegalsAction();

        selectDefaultItem();
    };

    const handleCloseCustomModal = () => {
        setOpenCustomModal(false);
    };

    const CustomModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Modal
                        open={openCustomModal}
                        onClose={handleCloseCustomModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={"custom-modal my-legals-modal"}
                    >
                        <Box sx={style}>
                            <Grid
                                container
                                className={"my-legals-modal-content centered"}
                            >
                                <Grid item xs={12} className={"icon-centered"}>
                                    {customModalIcon}
                                </Grid>
                                <Typography
                                    id="modal-modal-description"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        {customModalResponse}
                                    </span>
                                </Typography>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        );
    };

    const SuccessModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Modal
                        open={openSuccessModal}
                        onClose={handleCloseSuccessModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={"custom-modal my-legals-modal"}
                    >
                        <Box sx={style}>
                            <Grid
                                container
                                className={"my-legals-modal-content centered"}
                            >
                                <Grid item xs={12} className={"icon-centered"}>
                                    <img src={CheckImage} />
                                </Grid>
                                <Typography
                                    id="modal-modal-description"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        {successModalResponse}
                                    </span>
                                </Typography>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        );
    };

    const DeleteModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={"custom-modal my-legals-modal"}
                    >
                        <Box sx={style}>
                            <Grid
                                container
                                className={"my-legals-modal-content"}
                            >
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        Eliminar RFC
                                    </span>
                                </Typography>
                                <Typography
                                    id="modal-modal-description"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        El RFC "<b>{legalSelectedId}</b>" se
                                        eliminará de tus datos fiscales de forma
                                        irreversible
                                    </span>
                                </Typography>
                                <Grid
                                    container
                                    className={"modal-modal-buttons"}
                                    sx={{ mt: 2 }}
                                >
                                    <Button
                                        onClick={handleClose}
                                        className={
                                            "modal-modal-button modal-button-back"
                                        }
                                    >
                                        Volver
                                    </Button>
                                    <Button
                                        onClick={deleteLegal}
                                        className={
                                            "modal-modal-button modal-button-delete"
                                        }
                                    >
                                        Eliminar
                                    </Button>
                                </Grid>
                                <Typography
                                    id="modal-modal-response"
                                    sx={{ mt: 2 }}
                                >
                                    {response}
                                </Typography>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        );
    };

    const LegalItems = () => {
        return (
            <>
                {legal_ids?.map((legal, index) => (
                    <Grid
                        key={index}
                        container
                        className={
                            selectedItem === index
                                ? "my-legals-item selected item-" + index
                                : "my-legals-item item-" + index
                        }
                        onClick={() => selectItem(index, legal)}
                    >
                        <Grid
                            item
                            xs={2}
                            className={"my-legals-item-text first"}
                        >
                            RFC
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className={"my-legals-item-text third"}
                        >
                            {legal?.legal_id || "---"}
                        </Grid>
                    </Grid>
                ))}
            </>
        );
    };

    const LegalsList = () => {
        return (
            <Container>
                <Grid container className={"my-legals-wrapper"}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        className={"my-legals-content"}
                        mt={{ xs: 0, md: 1 }}
                    >
                        <Grid item xs={12} md={8} className={"my-legals-items"}>
                            <LegalItems />
                            <Grid
                                container
                                className={
                                    "my-legals-item item-default " +
                                    selectedDefault
                                }
                                onClick={() => selectDefaultItem()}
                            >
                                <Grid
                                    item
                                    xs={2}
                                    className={
                                        "my-legals-item-image first add-icon"
                                    }
                                >
                                    <AddCircleOutlineIcon />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid
                                    item
                                    xs={8}
                                    md={9}
                                    className={"my-legals-item-text second"}
                                >
                                    Nuevos datos fiscales
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        xs={12}
                        md={6}
                        className={"my-legals-content legals-right-container"}
                    >
                        {selectedItem === undefined ? <AddForm /> : <AddForm />}
                    </Grid>
                </Grid>
            </Container>
        );
    };

    useEffect(() => {
        const { payload, status, method } = myLegal || {};
        const { message, detail, title } = payload || {};

        if (message === "success") {
            cleanMyLegalAction();

            setOpen(false);
            setOpenSuccessModal(true);

            let actionMessage = "eliminado";
            if (method === "post") {
                reset();
                actionMessage = "agregado";
            } else if (method === "put") {
                actionMessage = "actualizado";
            }

            setSuccessModalResponse(
                <span>
                    El RFC se ha <b>{actionMessage}</b> con éxito
                </span>
            );
        } else if (status >= 400 && status < 500) {
            cleanMyLegalAction();

            setOpen(false);
            setOpenCustomModal(true);

            setCustomModalResponse(
                <Grid
                    container
                    className={"error-custom-modal-message-container"}
                >
                    <p className={"error-custom-modal-message-title"}>
                        {title}
                    </p>
                    <br />
                    <p className={"error-custom-modal-message-detail"}>
                        {detail}
                    </p>
                </Grid>
            );
            setCustomModalIcon(<img src={ErrorImage} />);
        }
        setLoading(false);
    }, [myLegal]);

    const deleteLegal = () => {
        deleteMyLegalAction({ legal_id: legalSelectedId });
    };

    return (
        <div>
            <PortalNavigation />
            <LegalsHeader />
            <Container
                maxWidth={false}
                className="container-min-height-500"
                sx={{ backgroundColor: "white", px: "0px !important" }}
            >
                <Grid container className={"my-legals-container"} pt={3}>
                    {legal_ids?.length < 1 ? (
                        <Grid item xs={12} className={"my-legals-content"}>
                            <AddForm />
                        </Grid>
                    ) : (
                        <LegalsList />
                    )}
                </Grid>
            </Container>

            <PortalFooter />
            <SuccessModal />
            <CustomModal />
            <DeleteModal />
        </div>
    );
};

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    myLegal: state?.myLegal,
    myLegals: state?.myLegals,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getMyLegalsAction,
    cleanMyLegalsAction,

    cleanMyLegalAction,
    postMyLegalAction,
    putMyLegalAction,
    deleteMyLegalAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(MyLegalsContent);
