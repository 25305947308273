export const inputCardStyle = {
    "& .MuiOutlinedInput-root": {
        background: "#ffffff",
        color: "#000",
        borderRadius: "5px",
        height: "55px",
        "&.Mui-focused": {
            color: "#e9e9e9",
        },
        "&:hover:not(.Mui-focused)": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#A361FF",
            },
        },
        "& input::placeholder": {
            color: "#43414A",
            fontSize: "15px",
        },
        "& input": {
            color: "#000",
        },
    },
};

export const pinContainer = {
    margin: "30px 0 0",
};
