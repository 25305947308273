import axiosAuthInstance from "../../../services/api/authentication";
import {
    SEND_EMAIL_COMPLETE_GIFT,
    SEND_EMAIL_COMPLETE_GIFT_ERROR,
} from "_models/redux/types";

// Función de acción

export const sendEmailAndCompleteGiftAction = (params) => async (dispatch) => {
    const { token, email } = params || "";
    console.log("sendEmailAndCompleteGiftAction -> params", params);
    try {
        const response = await axiosAuthInstance.post(
            `api/leads/steps/gift/complete-process`,
            { email },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: SEND_EMAIL_COMPLETE_GIFT,
            payload: data || {},
            status: status || {},
            method: "post",
        });
    } catch (e) {
        dispatch({
            type: SEND_EMAIL_COMPLETE_GIFT_ERROR,
            payload: e || {},
        });
    }
};
