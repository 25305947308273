import { Box, TextField, Typography, FormHelperText } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import { checkImeiGiftAction } from "_models/redux/check-imei-gift/action";
import { useCardPromotion } from "modules/gift/hooks/useCardPromotion";
import { cleanCheckImeiGift } from "_models/redux/check-imei-gift/clean-action";
import CustomDialog from "utils/helpers/genericModal/modal";

const CardImei = ({
    checkImeiGiftAction,
    checkImeiGift,
    cleanCheckImeiGift,
}) => {
    const {
        handleButtonClickWithLoader,
        loading,
        openModalMessage,
        openModal,
        handleClose,
        onSubmit,
        register,
        handleSubmit,
        errors,
    } = useCardPromotion({
        checkImeiGiftAction,
        checkImeiGift,
        cleanCheckImeiGift,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box className="card-imei-container">
                <Typography
                    variant="body1"
                    mb={{ xs: "31px", md: 2 }}
                    fontSize={16}
                >
                    O bien, prueba con otro equipo.
                </Typography>
                <Typography variant="body1" mb={1} fontSize={13}>
                    IMEI
                </Typography>
                <TextField
                    hiddenLabel
                    fullWidth
                    placeholder="Ingresa el IMEI de tu celular"
                    // sx={inputStyle}
                    {...register("imei", {
                        required: "IMEI es requerido",
                        minLength: {
                            value: 15,
                            message: "El IMEI debe tener 15 caracteres",
                        },
                        pattern: {
                            value: /^[0-9]{15}$/,
                            message: "El IMEI debe ser un número de 15 dígitos",
                        },
                        validate: (value) =>
                            value.trim().length === 15 ||
                            "El IMEI debe tener 15 caracteres",
                    })}
                    error={Boolean(errors.imei)}
                    onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s+/g, "");
                    }}
                />
                {errors.imei && (
                    <FormHelperText sx={{ color: "white" }}>
                        {errors.imei.message}
                    </FormHelperText>
                )}
                <Box mt={{ xs: "70px", md: "32px" }}>
                    <CustomButtonComponent
                        async={true}
                        bgColor={"primary-purple"}
                        text={"Consultar compatibilidad"}
                        height={45}
                        onClick={handleButtonClickWithLoader}
                        loading={loading}
                    />
                </Box>
                <CustomDialog
                    title="Error"
                    content={openModalMessage}
                    open={openModal}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                    backgroundColor="white"
                    extraButtons={[
                        {
                            label: "Cerrar",
                            variant: "contained",
                            onClick: handleClose,
                            buttonColor: "purple",
                            textColor: "white",
                        },
                    ]}
                />
            </Box>
        </form>
    );
};

const mapStateToProps = (state) => ({
    checkImeiGift: state.checkImeiGift,
});

const mapDispatchToProps = {
    checkImeiGiftAction,
    cleanCheckImeiGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardImei);
