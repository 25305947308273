export const alingStyleText = {
    textAlign: { xs: "center", md: "start" },
};

export const titleMessageStyle = {
    fontSize: { xs: 48, md: 70 },
    mt: { xs: 1, md: 0 },
    mb: { xs: -2, md: -3 },
};

export const subtitleMessageStyle = {
    fontSize: { xs: 49, md: 72 },
    mb: { xs: 3, md: 0 },
};

export const titleErrorMessageStyle = {
    fontSize: { xs: 41, md: 48 },
    mt: { xs: 2, md: 0 },
    mb: { xs: -2, md: 0 },
};

export const subtitleErrorMessageStyle = {
    fontSize: { xs: 24, md: 34 },
    mt: { xs: 2, md: 0 },
};

export const descriptionErrorMessageStyle = {
    fontSize: { xs: 18, md: 20 },
    mt: "40px",
};
