/* eslint-disable no-implicit-coercion */
/* eslint-disable no-undef */
import { useEffect } from "react";

let isPixelInitialized = false;

export const useMetaPixel = ({
    pixelID = process.env.REACT_APP_ESIM_REGALO_FACEBOOK_PIXEL,
    advancedMatching = {},
}) => {
    useEffect(() => {
        if (isPixelInitialized) return;
        if (!pixelID) return;
        isPixelInitialized = typeof window !== "undefined" && !!window.fbq;
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
        );

        fbq("init", pixelID, advancedMatching);
        fbq("track", "PageView");
    }, [advancedMatching, pixelID]);

    const trackFacebookPixel = (event, data = {}, options = {}) => {
        if (!isPixelInitialized || !pixelID) return;
        fbq("track", event, data, options);

        if (process.env.REACT_APP_DEBUG_PIXELS === "1") {
            console.log(`track fbq('track', '${event}')`, data, options);
        }
    };

    return {
        trackFacebookPixel,
    };
};
