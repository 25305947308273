import React from "react";

//CSS:
import "assets/styles/css/utils/components/flows-products/components/content-no-plans-products.css";

// Material Ui:
import { Box, Grid } from "@mui/material";

// Swiper:
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

//Images:
import Classic from "assets/images/products/clasicos-white.png";
import Lite from "assets/images/products/lite-white.png";
import Plus from "assets/images/products/plus-white.png";
import imgNoPlansPromoLite from "assets/images/utils/components/section-show-title/banner-meses-desk-LITE.png";
import imgNoPlansPromoLiteMobile from "assets/images/utils/components/section-show-title/banner-meses-movil-LITE.png";
import imgNoPlansPromoClassic from "assets/images/utils/components/section-show-title/banner-meses-desk-CLASSIC.png";
import imgNoPlansPromoClassicMobile from "assets/images/utils/components/section-show-title/banner-meses-movil-CLASSIC.png";
import imgNoPlansPromoPlus from "assets/images/utils/components/section-show-title/banner-meses-desk-PLUS.png";
import imgNoPlansPromoPlusMobile from "assets/images/utils/components/section-show-title/banner-meses-movil-PLUS.png";

//Components:
import AProductCardsItem from "modules/purchase/pages/widgets/productCardsItem";
import BProductCardsItem from "modules/compras/pages/widgets/productCardsItem";
import SectionCarouselProductComponent from "utils/components/section-carousel-product/SectionCarouselProductComponent";
import ProductCardsItemPrepaid from "modules/portal/pages/products/widgets/ProductCardsItemPrepaid";

function ContentNoPlansProductsComponents({
    classicSorted,
    plusSorted,
    products,
    flow,
}) {
    const ProductCard =
        flow === "prepaid"
            ? ProductCardsItemPrepaid
            : flow === "purchase"
              ? AProductCardsItem
              : BProductCardsItem;

    const swiperStyle = {
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const boxImgPromoResponsive = {
        display: { xs: "flex", md: "none" },
        my: 1,
    };

    const boxImgPromoInterestFree = {
        pt: { xs: 2, md: 2 },
        display: { xs: "none", md: "flex" },
    };

    return (
        <Box className="container-content-no-plans-products">
            {/*** NO Planes - LITE ***/}
            <Box sx={{ mt: 5, textAlign: "center" }}>
                <img
                    src={Lite}
                    alt="Titulo de los paquetes Lite"
                    className="img-title-no-plans"
                />
                <Grid container display={"flex"} justifyContent={"center"}>
                    <Box
                        component="img"
                        src={imgNoPlansPromoLite}
                        alt="Banner de promoción de planes Lite"
                        sx={{
                            maxWidth: "100%",
                            height: "auto",
                            display: "block",
                            margin: "0 auto",
                            "@media (max-width: 900px)": {
                                height: "180px",
                            },
                            ...boxImgPromoInterestFree,
                        }}
                    />

                    <Box sx={boxImgPromoResponsive}>
                        <Box
                            component="img"
                            src={imgNoPlansPromoLiteMobile}
                            alt="Banner de promoción de planes Lite en móvil"
                            sx={{
                                width: "fit-content",
                                height: "110px",
                                "@media (max-width: 900px)": {
                                    width: "100%",
                                    height: "180px",
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </Box>
            <SectionCarouselProductComponent showButton={false}>
                {products?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            is_monthly,
                            gigabyte_divisor,
                            ...rest
                        },
                        i
                    ) => {
                        const sumGigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const gigaBytes = sumGigaBytes / gigabyte_divisor;

                        return is_monthly && gigaBytes <= 15 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={0}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        is_monthly,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls={""}
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}
            </SectionCarouselProductComponent>

            {/*** NO Planes - CLÁSICOS ***/}
            <Box sx={{ mt: 10, textAlign: "center" }}>
                <img
                    src={Classic}
                    alt="Titulo de los paquetes Classic"
                    className="img-title-no-plans"
                />
                <Box>
                    <Grid container display={"flex"} justifyContent={"center"}>
                        <Box
                            component="img"
                            src={imgNoPlansPromoClassic}
                            alt="Banner de promoción de planes Lite"
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                                display: "block",
                                margin: "0 auto",
                                "@media (max-width: 900px)": {
                                    height: "180px",
                                },
                                ...boxImgPromoInterestFree,
                            }}
                        />
                        <Box sx={boxImgPromoResponsive}>
                            <Box
                                component="img"
                                src={imgNoPlansPromoClassicMobile}
                                alt="Banner de promoción de planes Lite en móvil"
                                sx={{
                                    width: "fit-content",
                                    height: "110px",
                                    "@media (max-width: 900px)": {
                                        width: "100%",
                                        height: "180px",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Box>
            </Box>
            <SectionCarouselProductComponent showButton={false}>
                {classicSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;

                        return i === 0 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={1}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls=""
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}

                {classicSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            product_duration,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                  ? AProductCardsItem
                                  : BProductCardsItem;

                        return product_duration / 30 === 12 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={1}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        product_duration,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls="recommended"
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}

                {classicSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            product_duration,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                  ? AProductCardsItem
                                  : BProductCardsItem;

                        return product_duration / 30 !== 12 && i > 0 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={1}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        product_duration,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls=""
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}
            </SectionCarouselProductComponent>

            {/*** NO Planes - PLUS ***/}
            <Box sx={{ mt: 10, textAlign: "center" }}>
                <img
                    src={Plus}
                    alt="Titulo de los paquetes Plus"
                    className="img-title-no-plans"
                />
                <Box>
                    <Grid container display={"flex"} justifyContent={"center"}>
                        <Box
                            component="img"
                            src={imgNoPlansPromoPlus}
                            alt="Banner de promoción de planes Lite"
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                                display: "block",
                                margin: "0 auto",
                                "@media (max-width: 900px)": {
                                    height: "180px",
                                },
                                ...boxImgPromoInterestFree,
                            }}
                        />
                        <Box sx={boxImgPromoResponsive}>
                            <Box
                                component="img"
                                src={imgNoPlansPromoPlusMobile}
                                alt="Banner de promoción de planes Lite en móvil"
                                sx={{
                                    width: "fit-content",
                                    height: "110px",
                                    "@media (max-width: 900px)": {
                                        width: "100%",
                                        height: "180px",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Box>
            </Box>
            <SectionCarouselProductComponent showButton={false}>
                {plusSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                  ? AProductCardsItem
                                  : BProductCardsItem;

                        return i === 0 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={2}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls=""
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}

                {plusSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            product_duration,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                  ? AProductCardsItem
                                  : BProductCardsItem;

                        return product_duration / 30 === 12 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={2}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        product_duration,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls="recommended"
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}

                {plusSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            product_duration,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                  ? AProductCardsItem
                                  : BProductCardsItem;

                        return product_duration / 30 !== 12 && i > 0 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={2}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        product_duration,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls=""
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}
            </SectionCarouselProductComponent>
        </Box>
    );
}

export default ContentNoPlansProductsComponents;
