import React from "react";
import { Typography } from "@mui/material";
import {
    typographyGB,
    typographyGBSpan,
    typographyPlusGB,
} from "modules/gift/styles-mui/giftStyleMUI";

const GBSection = () => (
    <>
        <Typography sx={typographyGB}>
            10
            <Typography
                variant="span"
                sx={{
                    fontSize: 63,
                    mt: 3,
                }}
            >
                GB
            </Typography>
            <Typography variant="span" sx={typographyGBSpan}>
                *
            </Typography>
        </Typography>
        <Typography sx={typographyPlusGB} variant="p">
            + Redes Sociales
        </Typography>
    </>
);

export default GBSection;
