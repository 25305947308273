import { Box, Typography } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import React from "react";

export const ChartCoverage = ({ coveragePercentage = 0 }) => {
    return (
        <Box className="chart-coverage">
            <svg width="0" height="0">
                <defs>
                    <linearGradient
                        id="gradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="100%"
                    >
                        <stop
                            offset="0%"
                            style={{
                                stopColor: "#B427FF",
                                stopOpacity: 1,
                            }}
                        />
                        <stop
                            offset="100%"
                            style={{
                                stopColor: "#8677F4",
                                stopOpacity: 1,
                            }}
                        />
                    </linearGradient>
                </defs>
            </svg>
            <Gauge
                value={coveragePercentage}
                startAngle={-110}
                endAngle={110}
                width={250}
                height={250}
                cornerRadius="50%"
                text={({ value }) => `${value}%`}
                innerRadius="75%"
                sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 30,
                        fontFamily: "Poppins-SemiBold",
                        transform: "translate(0px, -10px)",
                    },
                    [`& .${gaugeClasses.valueText} text`]: {
                        fill: "#fff !important",
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                        fill: "url(#gradient)",
                    },
                }}
            />

            <Typography variant="p" className="coverage-regular">
                Regular
            </Typography>
            <Typography variant="p" className="coverage-bad">
                Mala
            </Typography>
            <Typography variant="p" className="coverage-good">
                Buena
            </Typography>
        </Box>
    );
};
