import React from "react";

//Material UI:
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

//Styles MUI:
import {
    boxContainerNewDataInvoice,
    typographyTitleCard,
    textFieldItemValue,
    typographyItem,
} from "./styles-mui/newDataInvoiceStylesMUI";

function NewDataInvoiceComponent({ valuesMetodo, register }) {
    const taxRegime = [
        {
            id: "601",
            name: "General de Ley Personas Morales",
        },
        {
            id: "603",
            name: "Personas Morales con Fines no Lucrativos",
        },
        {
            id: "606",
            name: "Arrendamiento",
        },
        {
            id: "612",
            name: "Personas Físicas con Actividades Empresariales y Profesionales",
        },
        {
            id: "620",
            name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
        },
        {
            id: "621",
            name: "Incorporación Fiscal",
        },
        {
            id: "622",
            name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
        },
        {
            id: "623",
            name: "Opcional para Grupos de Sociedades",
        },
        {
            id: "624",
            name: "Coordinados",
        },
        {
            id: "625",
            name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
        },
        {
            id: "626",
            name: "Régimen Simplificado de Confianza",
        },
    ];

    const cfdi = [
        {
            id: "G01",
            name: "Adquisición de mercancías",
        },
        {
            id: "G03",
            name: "Gastos en general",
        },
    ];

    return (
        <Box sx={boxContainerNewDataInvoice}>
            <Typography variant="h2" sx={typographyTitleCard}>
                Datos de la factura
            </Typography>

            <Typography sx={typographyItem}>RFC *</Typography>
            <TextField
                required
                {...register("legal_id")}
                sx={textFieldItemValue}
                fullWidth
                id="legal_id"
                label="RFC"
                variant="outlined"
            />

            <Typography sx={typographyItem}>Razón social *</Typography>
            <TextField
                required
                {...register("legal_name")}
                sx={textFieldItemValue}
                fullWidth
                id="legal_name"
                label="Razón social"
                variant="outlined"
            />

            <Typography sx={typographyItem}>Régimen fiscal *</Typography>
            <FormControl fullWidth>
                <InputLabel id="select-regimen-fiscal-label">
                    Régimen fiscal
                </InputLabel>
                <Select
                    required
                    {...register("tax_regime")}
                    fullWidth
                    sx={textFieldItemValue}
                    labelId="select-regimen-fiscal-label"
                    label="Régimen fiscal"
                >
                    {taxRegime?.map((option) => (
                        <MenuItem key={option?.id} value={option?.id || 0}>
                            {option?.id + " - " + option?.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Typography sx={typographyItem}>Código postal *</Typography>
            <TextField
                required
                {...register("zipcode")}
                sx={textFieldItemValue}
                fullWidth
                id="zipcode"
                label="Código postal a 5 dígitos"
                variant="outlined"
            />

            <Typography sx={typographyItem}>Uso de CFDi *</Typography>
            <FormControl fullWidth>
                <InputLabel id="select-uso-de-cfdi-label">
                    Uso de CFDi
                </InputLabel>
                <Select
                    required
                    {...register("cfdi_use_id")}
                    fullWidth
                    sx={textFieldItemValue}
                    labelId="select-uso-de-cfdi-label"
                    label="Uso de CFDi"
                >
                    <MenuItem value={"Uso de CFDi"}>Uso de CFDi</MenuItem>
                    {cfdi?.map((option) => (
                        <MenuItem key={option?.id} value={option?.id || 0}>
                            {option?.id + " - " + option?.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Typography sx={typographyItem}>
                Seleccione método de pago *
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="select-metodo-pago">
                    Seleccione método de pago
                </InputLabel>
                <Select
                    required
                    {...register("payment_method_id")}
                    fullWidth
                    sx={textFieldItemValue}
                    labelId="select-metodo-pago"
                    label="Método de pago"
                >
                    {valuesMetodo?.map((option) => (
                        <MenuItem key={option?.id} value={option?.id || 0}>
                            {option?.id + " - " + option?.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Typography sx={typographyItem}>Correo electrónico *</Typography>
            <TextField
                required
                {...register("email")}
                sx={textFieldItemValue}
                fullWidth
                id="email"
                label="Correo electrónico"
                variant="outlined"
            />

            <FormGroup sx={{ mt: 1 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            {...register("store_legal_data")}
                            defaultChecked
                        />
                    }
                    label="Guardar datos de facturación"
                />
            </FormGroup>
        </Box>
    );
}

export default NewDataInvoiceComponent;
