import React from "react";
import { connect } from "react-redux";
import {
    Box,
    TextField,
    Typography,
    Modal,
    Button,
    CircularProgress,
} from "@mui/material";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import { inputCardStyle } from "./cardZipCodeStyleMUI";
import { useCardZipCode } from "modules/gift/hooks/useCardZipCode";
import { checkZipCodeGiftAction } from "_models/redux/zipcode-gift/action";
import { cleanCheckZipCodeGift } from "_models/redux/zipcode-gift/clean-action";

const CardZipCode = ({
    checkZipCodeGiftAction,
    checkZipCodeGift,
    cleanCheckZipCodeGift,
}) => {
    const {
        handleInputChange,
        handleButtonClick,
        zipCode,
        error,
        openModal,
        modalTitle,
        handleCloseModal,
        loading,
        apiError,
        modalMessage,
    } = useCardZipCode({
        checkZipCodeGiftAction,
        checkZipCodeGift,
        cleanCheckZipCodeGift,
    });

    return (
        <Box className="card-zip-container">
            <Typography variant="p" className="card-label" mb={1}>
                Código postal
            </Typography>
            <TextField
                fullWidth
                hiddenLabel
                variant="outlined"
                placeholder="Introduce tu código postal"
                sx={inputCardStyle}
                value={zipCode}
                onChange={handleInputChange}
                error={error}
                helperText={error ? "El código postal es requerido" : ""}
            />
            {apiError && (
                <Typography variant="body2" color="error" mt={1}>
                    {apiError}
                </Typography>
            )}
            <Box mt={"32px"} sx={{ position: "relative" }}>
                <CustomButtonComponent
                    async={true}
                    bgColor={"primary-purple"}
                    text={loading ? "" : "Buscar código postal"}
                    height={55}
                    onClick={handleButtonClick}
                    disabled={loading}
                />
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: "white",
                        borderRadius: 1,
                        boxShadow: 24,
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {modalTitle}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                        }}
                    >
                        <Button onClick={handleCloseModal}>Cerrar</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    checkZipCodeGift: state.checkZipCodeGift,
});

const mapDispatchToProps = {
    checkZipCodeGiftAction,
    cleanCheckZipCodeGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardZipCode);
