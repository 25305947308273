import imgBackground from "assets/images/portal/pages/payment-methods/card-texture.png";
export const boxContainer = {
    background:
        "transparent linear-gradient(168deg, #7143DE 0%, #855BEB 26%, #9F43FB 51%, #B55EE6 84%, #D16CD8 100%) 0% 0% no-repeat padding-box;",
    width: 310,
    height: 159,
    borderRadius: "10px",
    zIndex: 1,
};

export const cardContainer = {
    backgroundImage: `url(${imgBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: 310,
    height: 159,
    p: "21px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

export const buttonStyle = {
    backgroundColor: "primary.main",
    color: "white",
    "&:hover": {
        backgroundColor: "primary.dark",
    },
    width: 43,
    height: 43,
    borderRadius: "50%",
    opacity: "0.41",
};
