import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { saveCampaingData } from "utils/helpers/localstorage/campaing";

const INITIAL_STATE = {
    channelId: "",
    campaingId: "",
    referenceLead: "",
};

export const useCampaing = () => {
    const location = useLocation();
    const [campaingStore, setCampaingStore] = useState(INITIAL_STATE);

    useEffect(() => {
        const { channelId, campaingId, referenceLead, referer } =
            saveCampaingData();

        setCampaingStore({
            channelId,
            campaingId,
            referenceLead,
            referer,
        });
    }, [location]);

    return { ...campaingStore };
};
