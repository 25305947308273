import React, { useState } from "react";
import { connect } from "react-redux";
import CardGoodCoverage from "./Card-good-coverage/CardGoodCoverage";
import CardBadCoverage from "./card-bad-coverage/CardBadCoverage";
import { useCardGoodCoverage } from "modules/gift/hooks/useCardGoodCoverage";
import { setUserGiftAction } from "_models/redux/set-user-gift/action";
import CustomDialog from "utils/helpers/genericModal/modal";
import { cleanSetUserGift } from "_models/redux/set-user-gift/clean-action";

const CardCoverageResponse = ({
    isGoodCoverage,
    setUserGiftAction,
    setUserGift,
    cleanSetUserGift,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        openModal,
        modalMessage,
        handleDialogClose,
    } = useCardGoodCoverage({
        setUserGiftAction,
        setUserGift,
        cleanSetUserGift,
    });

    return (
        <>
            <CustomDialog
                title="Cobertura de Tarjeta"
                content={modalMessage}
                open={openModal}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="sm"
                backgroundColor="white"
                extraButtons={[
                    {
                        label: "Cerrar",
                        variant: "contained",
                        onClick: handleDialogClose,
                    },
                ]}
            />
            {isGoodCoverage ? (
                <CardGoodCoverage
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    onSubmit={onSubmit}
                    loading={loading}
                />
            ) : (
                <CardBadCoverage />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    setUserGift: state.setUserGift,
});
const mapDispatchToProps = {
    setUserGiftAction,
    cleanSetUserGift,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardCoverageResponse);
