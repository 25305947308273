import React, { useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { WhatsApp, MailOutline } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";

//Styles MUI:
import { buttonComponent } from "./styles-mui/codeOptionsToVerifyPin";

const CodeOptionsView = ({ handleButtonClick }) => {
    const [localLoadingButton, setLocalLoadingButton] = useState(null);

    const handleButtonClickWithAction = async (type) => {
        setLocalLoadingButton(type);
        await handleButtonClick(type);

        setLocalLoadingButton(null);
    };

    return (
        <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
            <Grid item xs={12} md={9}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={localLoadingButton === "sms"}
                    onClick={() => handleButtonClickWithAction("sms")}
                    startIcon={
                        localLoadingButton === "sms" ? (
                            <CircularProgress
                                size={20}
                                style={{ color: "#bbb4c7" }}
                            />
                        ) : (
                            <SmsOutlinedIcon />
                        )
                    }
                    sx={buttonComponent}
                >
                    SMS
                </LoadingButton>
            </Grid>

            <Grid item xs={12} md={9}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={localLoadingButton === "whatsapp"}
                    onClick={() => handleButtonClickWithAction("whatsapp")}
                    startIcon={
                        localLoadingButton === "whatsapp" ? (
                            <CircularProgress
                                size={20}
                                style={{ color: "#bbb4c7" }}
                            />
                        ) : (
                            <WhatsApp />
                        )
                    }
                    sx={buttonComponent}
                >
                    WhatsApp
                </LoadingButton>
            </Grid>

            <Grid item xs={12} md={9} mb={2}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    // loading={localLoadingButton === "email"}
                    onClick={() => handleButtonClickWithAction("email")}
                    startIcon={
                        localLoadingButton === "email" ? (
                            <CircularProgress
                                size={20}
                                style={{ color: "#bbb4c7" }}
                            />
                        ) : (
                            <MailOutline />
                        )
                    }
                    sx={buttonComponent}
                >
                    Correo electrónico
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

export default CodeOptionsView;
