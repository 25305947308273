export const gridContainerPurchase = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: { xs: 3, md: 1 },
};

export const gridItemTitle = {
    mt: { xs: 1, md: 4 },
    mb: { xs: 1, md: 0 },
};

export const typographyTitle = {
    fontSize: { xs: 22, md: 45 },
    fontWeight: "600",
    letterSpacing: 1.2,
    textAlign: "center",
    color: "white",
    my: { xs: 1, md: 0 },
};

export const typographySubTitle = {
    fontSize: { xs: 16.5, md: 20 },
    letterSpacing: 1.2,
    textAlign: "center",
    color: "white",
    m: { xs: 2, md: 3 },
};

export const gridContainerContent = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
};

export const gridItemContainerImg = { display: { xs: "none", lg: "block" } };

export const boxContainerImg = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
};
