import { Box, TextField, Typography, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { inputCardStyle } from "modules/gift/components/gift-compatibility/card-zip-code/cardZipCodeStyleMUI";
import { CardGift } from "modules/gift/components/shared/CardGift";
import { useCardZipCode } from "modules/gift/hooks/useCardZipCode";
import { Link } from "react-router-dom";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import { checkZipCodeGiftAction } from "_models/redux/zipcode-gift/action";
import CustomDialog from "utils/helpers/genericModal/modal";
import { cleanCheckZipCodeGift } from "_models/redux/zipcode-gift/clean-action";

const CardBadCoverage = ({
    checkZipCodeGiftAction,
    checkZipCodeGift,
    cleanCheckZipCodeGift,
}) => {
    const {
        handleInputChange,
        handleButtonClick,
        zipCode,
        error,
        openModal,
        modalTitle,
        modalMessage,
        handleCloseModal,
        coverageQuality,
        loading,
        ResponseError,
        handleSkipGoodCoverage,
    } = useCardZipCode({
        checkZipCodeGiftAction,
        checkZipCodeGift,
        cleanCheckZipCodeGift,
    });

    return (
        <CardGift width={{ xs: "100%", md: "405px" }}>
            <Link
                to="https://www.dalefon.mx/cobertura"
                target="_blank"
                className="link-coverage-map"
            >
                <Typography
                    variant="p"
                    md={1}
                    fontSize={{ xs: 20, md: 16 }}
                    fontWeight={700}
                    textAlign={{ xs: "center", md: "left" }}
                    className="purple-color"
                >
                    Consulta el mapa de cobertura,
                </Typography>
            </Link>

            <Typography
                variant="p"
                md={1}
                fontSize={{ xs: 18, md: 16 }}
                textAlign={{ xs: "center", md: "left" }}
            >
                o prueba con otro código postal.
            </Typography>
            <Typography
                variant="p"
                mb={1}
                fontSize={13}
                mt={{ xs: "39px", md: "28px" }}
            >
                Código postal
            </Typography>
            <TextField
                fullWidth
                hiddenLabel
                variant="outlined"
                placeholder="Introduce tu código postal"
                sx={inputCardStyle}
                value={zipCode}
                onChange={handleInputChange}
                error={error}
                helperText={error ? "El código postal es requerido" : ""}
            />
            {ResponseError && (
                <Typography variant="body2" color="error" mt={1}>
                    {ResponseError}
                </Typography>
            )}
            <Box mt={"32px"} mb={3} sx={{ position: "relative" }}>
                <CustomButtonComponent
                    async={true}
                    bgColor={"primary-purple"}
                    text={loading ? "" : "Buscar código postal"}
                    height={55}
                    onClick={handleButtonClick}
                    disabled={loading}
                />
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Box>
            <CustomDialog
                title={modalTitle}
                content={<Typography>{modalMessage}</Typography>}
                open={openModal}
                onClose={handleCloseModal}
                fullWidth
                maxWidth="sm"
                backgroundColor="white"
                extraButtons={[
                    {
                        label: "Cerrar",
                        variant: "contained",
                        onClick: handleCloseModal,
                        buttonColor: "primary",
                        textColor: "white",
                    },
                ]}
            />

            {coverageQuality === "Regular" && (
                <span
                    onClick={handleSkipGoodCoverage}
                    className="link-coverage-continue"
                >
                    Continuar de todos modos
                </span>
            )}
        </CardGift>
    );
};

const mapStateToProps = (state) => ({
    checkZipCodeGift: state.checkZipCodeGift,
});

const mapDispatchToProps = {
    checkZipCodeGiftAction,
    cleanCheckZipCodeGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardBadCoverage);
