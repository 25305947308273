import { useContext } from "react";

import { Grid, Button, Typography, Box } from "@mui/material";

import Compatibility from "assets/images/lead-recovery/compatibilidad.png";
import NoCompatible from "assets/images/lead-recovery/no-compatible.png";

import { LeadContext } from "../../data/contexts/leadContext";

const ImeiIncompatibleResponseComponent = () => {
    const leadContext = useContext(LeadContext);
    const { dispatchLeadData } = leadContext || {};

    return (
        <Grid container className="lead-recovery-wrapper ">
            <Grid
                item
                md={6}
                xs={12}
                className="lead-recovery-imei-section lead-container-congratulations"
                sx={{ borderRadius: 10 }}
            >
                <Box sx={{ p: { xs: 3, md: 5 }, textAlign: "center" }}>
                    <Typography
                        sx={{
                            fontSize: { xs: 24, md: 30 },
                            textAlign: "center",
                        }}
                    >
                        <span className="poppins-bold-font">Lo sentimos, </span>{" "}
                        <br></br>
                        <Typography
                            variant="span"
                            sx={{ fontSize: { xs: 18, md: 22 } }}
                        >
                            <span className="poppins-regular-font">
                                Tu equipo no es compatible con Dalefon
                            </span>
                        </Typography>
                    </Typography>

                    <Box
                        className="lead-recovery-imei-element"
                        sx={{
                            my: { xs: 0, md: 2 },
                            textAlign: "center",
                        }}
                    >
                        <img
                            src={NoCompatible}
                            className={"no-compatibility-image"}
                            alt="Imagen de incompatibilidad"
                        />
                    </Box>

                    <Button
                        type="button"
                        variant="text"
                        onClick={() =>
                            dispatchLeadData({
                                property: "compatibility",
                                value: undefined,
                            })
                        }
                        sx={{
                            fontSize: { xs: 16, md: 18 },
                            color: "#fd45d9",
                            textTransform: "none",
                            mt: { xs: 2, md: 0 },
                        }}
                    >
                        <span className="poppins-semibold-font">
                            ¡Inténtalo con otro equipo!
                        </span>
                    </Button>
                </Box>
            </Grid>
            <Grid
                item
                md={6}
                xs={12}
                className="display-flex justify-content-center responsive-hidden"
            >
                <img
                    src={Compatibility}
                    className={"compatibility-image"}
                    alt="Imagen de compatibilidad"
                />
            </Grid>
        </Grid>
    );
};

export default ImeiIncompatibleResponseComponent;
