import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { historyShoppingAction } from "../../../../../_models/redux/history-shopping/action";
import moment from "moment";
import PortalNavigation from "modules/portal/components/navigation";
import HistoryHeader from "./header";
import PortalFooter from "modules/portal/components/footer";

//CSS:
import "../../../../../assets/styles/css/portal/pages/history/components/history-list.css";

//Styles MUI:
import {
    boxContainerHistoryCard,
    boxMessageInvoiceGenerated,
    gridContainerHistoryDetail,
    gridContainerPurchaseDetails,
    gridItemMeansOfPayment,
    gridItemProductName,
    gridItemValidity,
    spanHistoryDetail,
    typographyTitleProductName,
} from "./styles-mui/historyListStylesMUI";

//React Router:
import { useNavigate } from "react-router-dom";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//React Icons:
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const HistoryListComponent = (props) => {
    const navigate = useNavigate();
    const { historyShopping } = props;
    const { historyShoppingObj } = historyShopping || "";
    const { transactions } = historyShoppingObj || [];

    useEffect(() => {
        props.historyShoppingAction();
    }, []);

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const timeActive = hoy.getTime();

    return (
        <div>
            <PortalNavigation />
            <HistoryHeader />
            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: "white",
                    px: "0px !important",
                    pt: 6,
                    pb: 5,
                }}
            >
                <Grid container sx={{ px: 1 }}>
                    {transactions?.map((element, i) => {
                        return (
                            <Grid item xs={12} md={12} sx={{ mb: 3.5 }} key={i}>
                                <Box
                                    className="history-card"
                                    sx={boxContainerHistoryCard}
                                >
                                    <Grid
                                        container
                                        className={
                                            timeActive <
                                            element.offer_expires_at * 1000
                                                ? "active-product"
                                                : "expired-product"
                                        }
                                        sx={gridContainerPurchaseDetails}
                                    >
                                        <Grid
                                            item
                                            xs={8}
                                            md={6}
                                            className="product-name"
                                            sx={gridItemProductName}
                                        >
                                            <Typography
                                                variant="h2"
                                                sx={typographyTitleProductName}
                                            >
                                                {element.product_name}
                                            </Typography>
                                            <Typography
                                                variant="p"
                                                sx={{ fontSize: "15px" }}
                                            >
                                                Fecha de compra{" "}
                                                {moment
                                                    .unix(element.created)
                                                    .format("DD/MM/YY")}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            md={6}
                                            sx={gridItemValidity}
                                        >
                                            {timeActive <
                                            element.offer_expires_at * 1000 ? (
                                                <Typography variant="p">
                                                    Vigente hasta el{" "}
                                                    {moment(
                                                        element.offer_expires_at *
                                                            1000
                                                    ).format("L")}
                                                </Typography>
                                            ) : (
                                                <Typography variant="p">
                                                    Expiró el{" "}
                                                    {moment(
                                                        element.offer_expires_at *
                                                            1000
                                                    ).format("L")}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        sx={gridContainerHistoryDetail}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{ my: 1 }}
                                        >
                                            <Typography
                                                variant="p"
                                                sx={{ fontSize: 18 }}
                                            >
                                                Monto:{" "}
                                                <Typography
                                                    variant="span"
                                                    sx={spanHistoryDetail}
                                                >
                                                    $
                                                    {Number(
                                                        element.amount
                                                    ).toLocaleString()}{" "}
                                                    MXN
                                                </Typography>
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={gridItemMeansOfPayment}
                                        >
                                            <Typography variant="p">
                                                Medio de pago:{" "}
                                                <Typography
                                                    variant="span"
                                                    sx={spanHistoryDetail}
                                                >
                                                    {element.application_source}
                                                </Typography>
                                            </Typography>
                                        </Grid>

                                        {element.self_invoiced ? (
                                            <Box
                                                sx={boxMessageInvoiceGenerated}
                                            >
                                                <IoMdCheckmarkCircleOutline />

                                                <Typography
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Factura generada
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Grid
                                                item
                                                xs={12}
                                                md={5}
                                                sx={{ my: 1 }}
                                            >
                                                <CustomButtonComponent
                                                    bgColor={"primary-purple"}
                                                    text={"Generar factura"}
                                                    height={"50px"}
                                                    onClick={() =>
                                                        navigate(
                                                            `/paquete/facturar`,
                                                            {
                                                                state: element,
                                                            }
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
            <PortalFooter />
        </div>
    );
};

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    historyShopping: state.historyShopping,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    historyShoppingAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryListComponent);
