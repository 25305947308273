import React from "react";

//Material UI:
import {
    Container,
    Grid,
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Box,
    Typography,
    Link,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

//Redux:
import { connect } from "react-redux";
import { loginAccountsAction } from "../../../_models/redux/login-account/action";

//Styles MUI:
import {
    gridContainer,
    formContainer,
    typographyTitleOne,
    typographyTitleTwo,
    formControlLabel,
    titleContainer,
    iconArrowBack,
    buttonComponent,
    checkboxComponent,
    linkComponent,
} from "../styles-mui/signUpStyleMUI";

//Components:
import LoginLinkComponent from "widgets/login/component";
import { usePasswordAcountController1 } from "../usePasswordAcountController";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function FormPasswordAccountViewComponent({
    loginAccount,
    loginAccountsAction,
}) {
    const { loginAccountObj } = loginAccount;
    const { response, data } = loginAccountObj || {};

    const {
        direct_number,
        name,
        last_name,
        mothers_last_name,
        email,
        loading,
        checkedConditions,
        dateOfBirth,
        gender,
        dateError,
        handleChangeConditions,
        registerOptions,
        setDirectNumber,
        setName,
        setMothers_last_name,
        setEmail,
        handleSubmit,
        onSubmit,
        errors,
        setDateOfBirth,
        setGender,
        setDateError,
        detail,
        register,
        createLast_name,
    } = usePasswordAcountController1({
        loginAccountObj,
        response,
        data,
        loginAccountsAction,
        loginAccount,
    });

    const [state, setState] = React.useState(true);

    const handleClick = () => {
        setState(!state);
    };

    return (
        <Container maxWidth="lg" sx={gridContainer}>
            <Grid container display={"flex"} alignItems={"center"}>
                <Grid item xs={12} md={7} sx={titleContainer}>
                    <a href="/client">
                        <ArrowCircleLeftIcon sx={iconArrowBack} />
                    </a>
                    <Typography sx={typographyTitleOne}>Crea tu</Typography>
                    <Typography sx={typographyTitleTwo}>
                        cuenta Dalefon
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5} sx={formContainer}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <TextField
                                    fullWidth
                                    {...register("name", registerOptions.name)}
                                    id="outlined-textarea"
                                    label="Nombre"
                                    placeholder="Nombre"
                                    value={name}
                                    onChange={(event) => {
                                        setName(event.target.value);
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&:hover fieldset": {
                                                borderColor: "#612cab",
                                            },
                                        },
                                    }}
                                />
                                <span>
                                    {errors?.name && (
                                        <p className="required-incidence">
                                            {" "}
                                            {errors.name.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <Grid container spacing={{ xs: 0, md: 3 }}>
                                <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                                    <TextField
                                        fullWidth
                                        {...register(
                                            "last_name",
                                            registerOptions.last_name
                                        )}
                                        id="outlined-required"
                                        label="Apellido paterno"
                                        placeholder="Apellido paterno"
                                        value={last_name}
                                        onChange={(event) => {
                                            createLast_name(event.target.value);
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            },
                                        }}
                                    />
                                    <span>
                                        {errors?.last_name && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.last_name.message}
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                                    <TextField
                                        {...register(
                                            "mothers_last_name",
                                            registerOptions.mothers_last_name
                                        )}
                                        fullWidth
                                        id="outlined-required"
                                        label="Apellido materno"
                                        placeholder="Apellido materno"
                                        value={mothers_last_name}
                                        onChange={(event) => {
                                            setMothers_last_name(
                                                event.target.value
                                            );
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            },
                                        }}
                                    />
                                    <span>
                                        {errors?.mothers_last_name && (
                                            <p className="required-incidence">
                                                {" "}
                                                {
                                                    errors.mothers_last_name
                                                        .message
                                                }
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <TextField
                                    fullWidth
                                    {...register(
                                        "direct_number",
                                        registerOptions.direct_number
                                    )}
                                    id="outlined-required"
                                    label="Número celular"
                                    placeholder="Número Dalefon a 10 dígitos"
                                    value={direct_number}
                                    onChange={(event) => {
                                        setDirectNumber(event.target.value);
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&:hover fieldset": {
                                                borderColor: "#612cab",
                                            },
                                        },
                                    }}
                                />
                                <span>
                                    {errors?.direct_number && (
                                        <p className="required-incidence">
                                            {" "}
                                            {errors.direct_number.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <Grid container spacing={{ xs: 0, md: 3 }}>
                                <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                                    <TextField
                                        {...register(
                                            "dateOfBirth",
                                            registerOptions.dateOfBirth
                                        )}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        id="date-of-birth"
                                        label="Fecha de Nacimiento"
                                        type="date"
                                        value={dateOfBirth}
                                        onChange={(event) => {
                                            const selectedDate = new Date(
                                                event.target.value
                                            );
                                            const maxDate = new Date();
                                            maxDate.setFullYear(
                                                maxDate.getFullYear() - 100
                                            );

                                            if (
                                                selectedDate > new Date() ||
                                                selectedDate < maxDate
                                            ) {
                                                setDateError(
                                                    "La fecha no es válida."
                                                );
                                                setDateOfBirth("");
                                            } else {
                                                setDateError("");
                                                setDateOfBirth(
                                                    event.target.value
                                                );
                                            }
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            },
                                        }}
                                    />
                                    <span>
                                        {errors?.dateOfBirth && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.dateOfBirth.message}
                                            </p>
                                        )}
                                    </span>
                                    {dateError && (
                                        <p className="required-incidence">
                                            {dateError}
                                        </p>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                                    <TextField
                                        {...register(
                                            "gender",
                                            registerOptions.gender
                                        )}
                                        select
                                        fullWidth
                                        id="gender"
                                        label="Género"
                                        value={gender}
                                        onChange={(event) =>
                                            setGender(event.target.value)
                                        }
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="M">Masculino</MenuItem>
                                        <MenuItem value="F">Femenino</MenuItem>
                                        <MenuItem value="X">
                                            No binario
                                        </MenuItem>
                                        <MenuItem value="-">
                                            Prefiero no responder
                                        </MenuItem>
                                    </TextField>
                                    <span>
                                        {errors?.gender && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.gender.message}
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 1 }}>
                                <TextField
                                    fullWidth
                                    {...register(
                                        "email",
                                        registerOptions.email
                                    )}
                                    id="outlined-textarea"
                                    label="Correo electrónico (opcional)"
                                    placeholder="Correo electrónico (opcional)"
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&:hover fieldset": {
                                                borderColor: "#612cab",
                                            },
                                        },
                                    }}
                                />
                                <span>
                                    {errors?.email && (
                                        <p className="required-incidence">
                                            {" "}
                                            {errors.email.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <FormControlLabel
                                required
                                sx={formControlLabel}
                                control={
                                    <Checkbox
                                        checked={checkedConditions}
                                        onChange={handleChangeConditions}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography sx={checkboxComponent}>
                                        <span> Acepto los </span>
                                        <Link
                                            href="https://dalefon.mx/terminos-y-condiciones/"
                                            variant="a"
                                            onClick={handleClick}
                                            target="_blank"
                                            sx={linkComponent}
                                        >
                                            términos y condiciones
                                        </Link>
                                        <span> y el </span>
                                        <Link
                                            href="https://dalefon.mx/aviso-de-privacidad"
                                            variant="a"
                                            onClick={handleClick}
                                            target="_blank"
                                            sx={linkComponent}
                                        >
                                            aviso de privacidad *
                                        </Link>
                                    </Typography>
                                }
                            />
                            <span>
                                <p className="required-incidence">{detail}</p>
                            </span>
                        </Grid>

                        <Box sx={buttonComponent}>
                            <CustomButtonComponent
                                async={true}
                                loading={loading}
                                bgColor={"primary-purple"}
                                text={"Continuar"}
                                height={55}
                            />
                        </Box>
                        <LoginLinkComponent />
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
}

// export default Modal;
const mapStateToProps = (state) => ({
    loginAccount: state.loginAccount,
});
const mapDispatchToProps = {
    loginAccountsAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormPasswordAccountViewComponent);
