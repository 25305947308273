import { Box, Typography } from "@mui/material";
import React from "react";
import "assets/styles/css/gift/gift-compatibility.css";
import { Link } from "react-router-dom";
import {
    descriptionErrorMessageStyle,
    subtitleErrorMessageStyle,
    subtitleMessageStyle,
    titleErrorMessageStyle,
    titleMessageStyle,
} from "./messageResponseStyle";
export const SuccessMessageResponse = () => {
    return (
        <Box className="message-container">
            <Typography
                variant="p"
                color={"white"}
                fontSize={{ xs: 20, md: 22 }}
                sx={{ lineHeight: "1.8" }}
            >
                <b className="font-medium">¡Felicidades!</b>{" "}
                <Typography variant="span" noWrap={true}>
                    Eres compatible con la eSIM
                </Typography>
            </Typography>
            <Typography
                variant="p"
                sx={titleMessageStyle}
                className="font-bold"
            >
                Ahora{" "}
                <Typography variant="span" className="purple-color">
                    revisa
                </Typography>
            </Typography>
            <Typography
                variant="p"
                sx={subtitleMessageStyle}
                className="font-bold purple-color"
            >
                Tu cobertura
            </Typography>
        </Box>
    );
};

export const ErrorMessageResponse = () => {
    return (
        <Box className="message-container">
            <Typography
                variant="p"
                color={"white"}
                fontSize={{ xs: 26, md: 35 }}
                className="font-bold"
            >
                {":( "}
            </Typography>
            <Typography
                variant="p"
                sx={titleErrorMessageStyle}
                className="font-bold"
            >
                Lo sentimos
            </Typography>
            <Typography
                variant="p"
                sx={subtitleErrorMessageStyle}
                className="font-semibold purple-color"
            >
                no eres compatible con eSIM
            </Typography>
            <Typography variant="p" sx={descriptionErrorMessageStyle}>
                Pero puedes adquirir una SIM{" "}
                <Link to={"/purchase"} className="purple-color font-bold">
                    aquí.
                </Link>
            </Typography>
        </Box>
    );
};

export const MessageResponse = ({ isCompatibility = false }) => {
    return (
        <>
            {isCompatibility ? (
                <SuccessMessageResponse />
            ) : (
                <ErrorMessageResponse />
            )}
        </>
    );
};
