export const gridContainerNoData = {
    padding: "30px",
    maxWidth: "750px",
    margin: "0 auto",
};

export const gridItemNoData = { display: "flex", justifyContent: "flex-end" };

export const gridItemNoDataText = {
    borderRadius: "15px",
    padding: "20px 30px",
    boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)",
    background: "#fcf1ff",
    color: "#26202f",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
};

export const typographyNoDataText = {
    margin: 0,
    color: "#270c50",
};

export const typographyTextNoPlans = {
    fontSize: "18px",
    lineHeight: "35px",
};
