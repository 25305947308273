import React from "react";
import { Container, Grid } from "@mui/material";

const HistoryHeader = (props) => {
    return (
        <div>
            <Container className={"my-legals-container"}>
                <Grid container className={"my-legals-header"}>
                    <Grid item xs={12} className={"my-legals-title"}>
                        <h3>
                            Mis <span>datos</span> fiscales
                        </h3>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default HistoryHeader;
