import { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { LeadContext } from "../data/contexts/leadContext";

import HelperFormInputComponent from "utils/helpers/forms/inputs/helper-form-input-component";
import ImeiResponseComponent from "./imei/LeadImeiResponseComponent";

import Compatibility from "assets/images/lead-recovery/compatibilidad.png";

import { useLeadImeiHook } from "../hooks/leadImeiHook";

import LoadingButton from "@mui/lab/LoadingButton";

const LeadImeiComponent = () => {
    const leadContext = useContext(LeadContext);

    /*** ***/
    const { leadData, reduxValues, formValues, recaptchaContainerProps } =
        leadContext || {};
    const { inputs, compatibility } = leadData || {};

    const { imeiInputs } = inputs || {};

    /*** ***/

    const { postLeadCheckImei, postLeadCheckImeiAction } = reduxValues || {};

    const { control, register } = formValues;

    /*** ***/

    const { token, setCallActionFlag, reCaptchaAction, setReCaptchaAction } =
        recaptchaContainerProps || {};

    /*** ***/

    const {
        //Reducer
        leadImeiReducer,
        //Functions
        searchCompatibility,
    } = useLeadImeiHook({
        postLeadCheckImei,
        postLeadCheckImeiAction,
        formValues,
        setCallActionFlag,
        setReCaptchaAction,
        reCaptchaAction,
        token,
    });

    const { loading } = leadImeiReducer || {};

    return (
        <Grid container className="lead-content" sx={{ my: { xs: 3, md: 8 } }}>
            <Grid container display={"flex"} alignItems={"center"}>
                {compatibility ? null : (
                    <Grid container className="lead-recovery-wrapper">
                        <Grid
                            item
                            md={6}
                            xs={12}
                            className="lead-recovery-section white-bg"
                        >
                            <Box
                                sx={{
                                    py: { xs: 3, md: 4 },
                                    px: { xs: 3, md: 6 },
                                }}
                            >
                                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                                <Typography sx={{ fontSize: 25, mb: 2 }}>
                                    <span className="poppins-semibold-font">
                                        Paso 2 de 3
                                    </span>
                                </Typography>

                                <Typography>
                                    <span className="poppins-semibold-font">
                                        ¿Tu equipo es compatible?
                                    </span>
                                </Typography>

                                <p className="poppins-regular-font font-size-16">
                                    Obten el IMEI de tu teléfono marcando{" "}
                                    <a
                                        style={{
                                            textDecoration: "none",
                                            color: "#fc2bdc",
                                        }}
                                        href="tel:*#06#"
                                    >
                                        *#06#
                                    </a>
                                </p>

                                {imeiInputs?.map((item, index) => {
                                    return (
                                        <HelperFormInputComponent
                                            register={register}
                                            control={control}
                                            key={index}
                                            id={item.id}
                                            validations={item.validations}
                                            placeholder={item.placeholder}
                                            // cls={item.cls}
                                            onChangeHandler={
                                                item.onChangeHandler
                                            }
                                            onFocusHandler={item.onFocusHandler}
                                            type={item.type}
                                            error={item.error}
                                            label={item.label}
                                            options={item.options}
                                            containerCls={item.containerCls}
                                            value={item.value}
                                            valueProgrammatically={
                                                item.valueProgrammatically
                                            }
                                        />
                                    );
                                })}

                                <Grid
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <LoadingButton
                                        loading={loading}
                                        loadingPosition="center"
                                        variant="outlined"
                                        className="compatibility-btn"
                                        onClick={() => searchCompatibility()}
                                        // type="submit"
                                    >
                                        <Typography fontSize={16}>
                                            <span className="poppins-semibold-font">
                                                Buscar compatibilidad
                                            </span>
                                        </Typography>
                                    </LoadingButton>
                                </Grid>
                                {/* </form> */}
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            className="display-flex justify-content-center responsive-hidden"
                        >
                            <img
                                src={Compatibility}
                                className={"compatibility-image"}
                                alt="Imagen de compatibilidad"
                            />
                        </Grid>
                    </Grid>
                )}

                <ImeiResponseComponent
                    imeiResponse={postLeadCheckImei}
                    compatibility={compatibility}
                />
            </Grid>
        </Grid>
    );
};

export default LeadImeiComponent;
