import { connect } from "react-redux";
import { useState } from "react";
import "assets/styles/css/gift/gift-verificacion.css";
import { CardGift } from "../../shared/CardGift";
import {
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import { VerificationPin } from "react-verification-pin";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
    resendPinGiftAction,
    verifyPinGiftAction,
} from "_models/redux/verification-pin-gift/action";
import { useCardVerification } from "modules/gift/hooks/useCardVerification";
import { NOTIFICATIONS_TYPES } from "_models/redux/types";
import { cleanVerifyPinGift } from "_models/redux/verification-pin-gift/clean-action";

const CardVerification = ({
    verifyPinGiftAction,
    verifyPinGift,
    resendPinGiftAction,
    cleanVerifyPinGift,
}) => {
    const [resetPin, setResetPin] = useState(false);
    const {
        status,
        handleOnFinish,
        handleSubmit,
        handleForward,
        handleClose,
        statusCode,
        intervalState,
        showReenviar,
        hanldeTryAgain,
        showMethods,
        method,
        handleSetMethod,
        modalMessage,
        openModal,
        handleCloseMethods,
        triggerPinReset,
    } = useCardVerification({
        verifyPinGiftAction,
        verifyPinGift,
        resendPinGiftAction,
        cleanVerifyPinGift,
        setResetPin,
    });
    return (
        <CardGift>
            <Typography
                variant="p"
                md={1}
                fontSize={{ xs: 18, md: 16 }}
                lineHeight={{ xs: 1.6, md: 2 }}
            >
                Ingresa el código que hemos enviado a tu número celular.
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box position={"relative"} className="verification-form">
                    <VerificationPin
                        type="number"
                        inputsNumber={5}
                        name="pinCode"
                        status={status}
                        onFinish={handleOnFinish}
                        key={resetPin ? "reset" : "active"}
                    />
                    {statusCode === 409 && (
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            className="error-verification"
                        >
                            <span className="error-title">
                                <ErrorOutlineIcon fontSize="12" />
                                &nbsp;Código inválido o expirado
                            </span>
                        </Box>
                    )}
                </Box>
                <Box mt={"32px"}>
                    <CustomButtonComponent
                        async={true}
                        bgColor={"primary-purple"}
                        text={"Continuar"}
                        height={45}
                        loading={status === "wait"}
                    />
                </Box>
            </form>

            <Typography
                variant="p"
                mt={"30px"}
                fontSize={14}
                textAlign={"center"}
            >
                ¿No recibiste el código?{" "}
                <span
                    className="font-medium pointer"
                    onClick={() => {
                        return showReenviar ? hanldeTryAgain() : null;
                    }}
                    style={{
                        opacity: showReenviar ? 1 : 0.6,
                        cursor: showReenviar ? "pointer" : "not-allowed",
                    }}
                >
                    {!showReenviar
                        ? `Reenvío en ${intervalState.minutes}:${intervalState.seconds}`
                        : "Reenviar código"}
                </span>
            </Typography>

            <Dialog
                open={showMethods}
                onClose={handleCloseMethods}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ¿Cómo deseas recibir tu código?
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <RadioGroup
                            name="radio-buttons-group"
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={method}
                        >
                            <FormControlLabel
                                value="sms"
                                control={<Radio />}
                                label="SMS"
                                onClick={() =>
                                    handleSetMethod(NOTIFICATIONS_TYPES.SMS)
                                }
                            />
                            <FormControlLabel
                                value="whatsapp"
                                control={<Radio />}
                                label="Whatsapp"
                                onClick={() =>
                                    handleSetMethod(
                                        NOTIFICATIONS_TYPES.WHATSAPP
                                    )
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleForward(method)}
                        color="primary"
                        autoFocus
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Token Expirado"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {modalMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </CardGift>
    );
};

const mapStateToProps = (state) => ({
    verifyPinGift: state.verifyPinGift,
});
const mapDispatchToProps = {
    verifyPinGiftAction,
    resendPinGiftAction,
    cleanVerifyPinGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardVerification);
