export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 260, md: 305 },
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
};

export const modalTitleStyle = {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontFamily: "Poppins-SemiBold",
    fontSize: 16,
};
