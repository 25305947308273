import { useMetaPixel } from "./usePixelMeta";
import { encryptToSHA256 } from "utils/helpers/crypto/encryptToSHA256";
import { eventsNameMetaPixel } from "../const/eventsNameMetaPixel";

export const usePixels = () => {
    const { trackFacebookPixel } = useMetaPixel({});

    const pixelViewContent = (leadToken) => {
        genericEvent(eventsNameMetaPixel.viewContent, leadToken);
    };

    const pixelSearch = (leadToken) => {
        genericEvent(eventsNameMetaPixel.search, leadToken);
    };

    const pixelContact = ({ fullName, phone, leadToken }) => {
        if (!leadToken) return;
        const { lead_id } = decodeJWT(leadToken);

        const fn = encryptToSHA256(fullName);
        console.log("🚀 ~ pixelContact ~ fn:", fn);
        const ph = encryptToSHA256(phone);

        trackFacebookPixel(
            eventsNameMetaPixel.contact,
            {
                external_id: lead_id,
                fn,
                ph,
            },
            { eventID: lead_id }
        );
    };

    const pixelCompleteRegistration = (leadToken) => {
        genericEvent(eventsNameMetaPixel.completeRegistration, leadToken);
    };

    const pixelStartTrial = (leadToken) => {
        if (!leadToken) return;
        const { lead_id } = decodeJWT(leadToken);

        trackFacebookPixel(
            eventsNameMetaPixel.startTrial,
            {
                external_id: lead_id,
                value: "0.00",
                currency: "MXN",
                predicted_ltv: "0.00",
            },
            { eventID: lead_id }
        );
    };

    const genericEvent = (eventName, leadToken) => {
        if (!leadToken) return;
        const { lead_id } = decodeJWT(leadToken);

        trackFacebookPixel(
            eventName,
            {
                external_id: lead_id,
            },
            { eventID: lead_id }
        );
    };

    const decodeJWT = (token) => {
        const parts = token.split(".");
        if (parts.length !== 3) {
            throw new Error("El token JWT no es válido");
        }

        const decodedPayload = atob(parts[1]);
        const payload = JSON.parse(decodedPayload);

        return payload;
    };

    return {
        pixelViewContent,
        pixelContact,
        pixelSearch,
        pixelCompleteRegistration,
        pixelStartTrial,
    };
};
