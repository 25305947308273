import { useContext, useEffect } from "react";
import { AppContext } from "_models/context/app";
import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import FacebookPixel from "react-facebook-pixel";
import { useNavigate } from "react-router-dom";
// import { clarity } from "react-microsoft-clarity";

export const HomeMenuController = ({
    completeProcess,
    cleanCompleteProcessAction,
}) => {
    const navigate = useNavigate();

    const appContext = useContext(AppContext);
    const { setPressed } = appContext;

    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_PURCHASE_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** ***/

    /*** TIKTOK pixels  ***/
    const tiktokPixelId = process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** ***/

    /*** FACEBOOK pixels  ***/
    const facebookPixelId =
        process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        autoConfig: true,
        debug: debugPixels,
    };

    /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_PURCHASE_CLARITY_PIXEL;

    /*** ***/

    useEffect(() => {
        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );
        FacebookPixel.pageView();
        /*** ***/

        // /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        // /*** ***/
    }, []);

    const purchaseEvent = () => {
        const ttTrackingOpt = {};
        TiktokPixel.track(
            "ClickButton",
            { description: "Purchase Button" },
            ttTrackingOpt
        );
        navigate("/purchase", {});
    };

    const clientEvent = () => {
        const ttTrackingOpt = {};
        TiktokPixel.track(
            "ClickButton",
            { description: "Client Button" },
            ttTrackingOpt
        );
        navigate(`/client`);
    };

    useEffect(() => {
        return () => {
            cleanCompleteProcessAction();
            window.onpopstate = () => {
                setPressed(false);
            };
        };
    }, []);

    return { clientEvent, purchaseEvent };
};
